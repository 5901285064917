import { apiPostCall } from "src/services/api/api.core";
import { EApiEndpoint } from "src/config/enums";

export const appointmentNoShowCharge = async ({ ids }: { ids: string[] }) => {
  return await apiPostCall(
    EApiEndpoint.AppointmentNoShowCharge,
    JSON.stringify(ids),
    {
      credentials: true
    }
  );
};
