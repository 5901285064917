import React from "react";
import * as Styled from "./OxStaffDashboardMyDetails.styled";
import { graphql, useStaticQuery } from "gatsby";
import { API_ENDPOINT } from "src/config/constants";
import { OxIcon } from "src/components/OxIcon";

type TProps = {
  id: string;
  image: string | undefined;
  fullName: string;
  phoneNumber: string | undefined;
  email: string | undefined;
  disableEdit?: boolean;
  header?: string;
  hasPatientAppointmentNotes?: boolean;
};

export const OxStaffDashboardMyDetails = (props: TProps): JSX.Element => {
  const data = useStaticQuery(
    graphql`
      query {
        user: file(relativePath: { eq: "user.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const labels = [
    { label: "Tel:", labelData: props.phoneNumber },
    { label: "Email:", labelData: props.email },
  ];

  const imageProps = {
    image: props.image ? `${API_ENDPOINT}${props.image}` : undefined,
    fluidImages: !props.image
      ? data.user.childImageSharp.gatsbyImageData
      : undefined,
  };

  return (
    <Styled.Container id={props.id}>
      <Styled.FullName>
        {props.fullName}
        {!!props?.hasPatientAppointmentNotes && (
          <Styled.PatientNotes>
            <OxIcon name="warning" title="Patient has appointment notes" />
          </Styled.PatientNotes>
        )}
      </Styled.FullName>
    </Styled.Container>
  );
};
