import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { OxSearchPatient } from "src/panel/components/OxWelcomeIntenalFlow/components/OxSearchPatient";
import { TPatientSearchResult } from "src/services/api/api.types";
import { ERoutes } from "src/config/enums";
import { OxStaffDashboardRoot } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot";
import { applyValues } from "src/utils/applyValues";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxStaffDashboardPatientSearch: React.FC<RouteComponentProps> = ({
  location,
   clinicId
}) => {
  const onSelect = (
    patient: TPatientSearchResult,
    searchTerm?: string
  ): void => {
    navigate(
      applyValues(ERoutes.PanelStaffDashboardPatientById, {
        patientId: patient.patients?.[clinicId]?.patientId ?? "",
      }),
      {
        state: { searchTerm },
      }
    );
  };

  return (
    <OxStaffDashboardRoot location={location} noPadding={true}>
      <OxPageHelmet title="Patient Search" />
      <OxSearchPatient
        onSelect={onSelect}
        hideReturn={true}
        searchTerm={location?.state?.searchTerm}
      />
    </OxStaffDashboardRoot>
  );
};
