import { EFontWeight, EFonts } from "src/config/enums";
import { fluidSizing } from "src/utils";
import styled, { css } from "styled-components";
import { OxDashboardContentWrapper } from "src/panel/components/OxDashboardContentWrapper";
import { createTextStyle } from "src/utils/createTextStyle";

export const Container = styled(OxDashboardContentWrapper)`
  position: relative;
`;

export const Text = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.only("xs")} {
      ${fluidSizing([
        { prop: "padding-left ", values: [28, null, null] },
        { prop: "padding-right ", values: [28, null, null] }
      ])}
    }
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Light,
      [13, 13, 13],
      [19, 19, 19]
    )}
  `
);
