import React from "react";
import * as Styled from "./OxPatientsSliderItem.styled";
import { TAppointment, TPatientProfile } from "src/services/api/api.types";
import { OxIcon } from "src/components/OxIcon";
import { OxStaffDashboardPatientExtras } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPatientExtras";
import { DateTime } from "luxon";
import { useStore } from "react-redux";
import { ERoles } from "src/config/enums";
import { OxPatientArrivalStatus } from "./components/OxPatientArrivalStatus";

type TProps = {
  consultation: TAppointment & { hide: boolean };
  extrasElement?: React.FC<TAppointmentExtras> | false;
};

export type TAppointmentExtras = {
  patientId: string;
  patientName: string;
  patientProfile?: TPatientProfile;
  appointment?: TAppointment;
};

export const OxPatientsSliderItem = ({
  consultation,
  extrasElement = OxStaffDashboardPatientExtras
}: TProps): JSX.Element => {
  const store = useStore();
  const { user } = store.getState();

  return (
    <Styled.Container
      hide={consultation.hide}
      data-appointment-id={consultation.id}
    >
      <Styled.PatientName>{consultation?.patientName}</Styled.PatientName>

      {!!consultation?.hasPatientAppointmentNotes && (
        <Styled.PatientNotes>
          <OxIcon name="warning" title="Patient has appointment notes" />
        </Styled.PatientNotes>
      )}

      {!!consultation.archetype && (
        <Styled.Archetype archetype={consultation.archetype} />
      )}

      <Styled.AppointmentData>
        {DateTime.fromISO(consultation.startsAt ?? "").toLocaleString(
          DateTime.DATE_MED
        ) +
          " at " +
          DateTime.fromISO(consultation.startsAt ?? "").toLocaleString(
            DateTime.TIME_SIMPLE
          )}
      </Styled.AppointmentData>
      <Styled.Text>{consultation.description}</Styled.Text>
      {!!user && !user?.roles.includes(ERoles.Practitioner) && (
        <Styled.Text>{consultation.practitionerName}</Styled.Text>
      )}

      {!!user && user?.roles.includes(ERoles.Receptionist) && (
        <OxPatientArrivalStatus consultation={consultation} />
      )}
      {extrasElement &&
        React.createElement(extrasElement, {
          patientName: consultation.patientName ?? "",
          patientId: `${consultation.patientId}`,
          appointment: consultation
        })}
    </Styled.Container>
  );
};
