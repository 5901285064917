import React, { useContext, useEffect, useState } from "react";
import * as Styled from "./OxStaffDashboardAppointments.styled";
import {
  EFilterType,
  OxPatientsFilters,
  TFilter,
  TRangeFilter
} from "./components/OxPatientsFilters";
import {
  OxPatientsSliderItem,
  TAppointmentExtras
} from "./components/OxPatientsSliderItem";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import { useStore } from "react-redux";
import {
  TAppointment,
  TClinic,
  TClinicAppointments,
  TUser
} from "src/services/api/api.types";
import { TLocationFilter } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointmentCalendar";
import { getPractitionerAppointment } from "src/services/api/practitioner";
import { getAppointments } from "src/services/api/clinicDashboard";
import { DateTime } from "luxon";
import { ERoles } from "src/config/enums";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { useInView } from "src/hooks/useInViewport";

export enum EAppointmentsType {
  LOCATION = "TYPE_LOCATION",
  USER = "TYPE_USER"
}

type TProps = {
  showLocations?: boolean;
  showFilters?: boolean;
  noPreConsultations?: boolean;
  type: EAppointmentsType;
  extrasElement?: React.FC<TAppointmentExtras> | false;
};

let refreshInit: NodeJS.Timeout;

export const OxStaffDashboardAppointments = ({
  showLocations = false,
  showFilters = true,
  noPreConsultations,
  type,
  extrasElement
}: TProps) => {
  const { validateResponse } = useValidateResponse();
  const store = useStore();
  const { user }: { user?: TUser } = store.getState();
  const [location, setLocation] = useState<TLocationFilter | null>(null);
  const [firstConsultationList, setFirstConsultationList] = useState<
    TClinicAppointments
  >({});
  const websiteDataContext = useContext(WebsiteDataContext);

  const fullLocation: TClinic[] = websiteDataContext.locations?.filter(
    item => type === EAppointmentsType.USER || item.id === user?.clinicId
  );

  const locations = fullLocation.map(item => ({
    id: item.businessId as string,
    name: item.displayName as string
  }));

  const noPreConsultationsFilter: TFilter = {
    type: EFilterType.NEq,
    key: "practitionerId",
    values: [fullLocation.pop()?.preTreatmentPractitionerId ?? ""]
  };

  const [filters, setFilters] = useState<TFilter[]>(
    noPreConsultations && fullLocation ? [noPreConsultationsFilter] : []
  );

  useEffect(() => {
    if (
      noPreConsultations &&
      !!fullLocation &&
      JSON.stringify(filters) !== JSON.stringify([noPreConsultationsFilter])
    ) {
      setFilters(prev => [...prev, noPreConsultationsFilter]);
    }
  }, [fullLocation]);

  const fetchAppointmentList = async (): Promise<TClinicAppointments> => {
    const list =
      type === EAppointmentsType.USER
        ? await getPractitionerAppointment("today", "today")
        : await getAppointments(user?.clinicId ?? "");

    try {
      validateResponse(list);
    } catch (e) {
      console.error(e.error.title);
      return {};
    }

    return list || {};
  };

  const getLocationFirstConsultation = (): TAppointment[] => {
    if (!location)
      return Object.keys(firstConsultationList).reduce((acc, currKey) => {
        return acc.concat(firstConsultationList[currKey].appointments);
      }, []);

    return firstConsultationList[location.id].appointments;
  };

  const firstConsultation = getLocationFirstConsultation().filter(Boolean);

  const sortAppointments = (a: TAppointment, b: TAppointment) =>
    DateTime.fromISO(a.startsAt ?? "").diff(
      DateTime.fromISO(b.startsAt ?? ""),
      "second"
    ).seconds;

  const filteredFirstConsultation = (): (TAppointment & {
    hide: boolean;
  })[] => {
    const appointments = [...firstConsultation].map(appointment =>
      Object.assign(appointment, { hide: false })
    );

    appointments.forEach(appointment => {
      let hide = appointment.hide;
      filters.forEach(filter => {
        if (filter.values.length === 0) return;

        if (filter.type === EFilterType.DateRange) {
          hide =
            hide ||
            !filter.values.find((range: TRangeFilter) => {
              const value = DateTime.fromISO(appointment[filter.key])
                .setZone(user.clinic?.timezone ?? "Europe/London")
                .toISO();
              let match = true;
              if (range.from) {
                match = match && value >= range.from.toISO();
              }

              if (range.to) {
                match = match && value < range.to.toISO();
              }

              return match;
            });
        } else if (filter.type === EFilterType.NEq) {
          hide = hide || filter.values.includes(appointment[filter.key]);
        } else {
          hide = hide || !filter.values.includes(appointment[filter.key]);
        }
      });

      appointment.hide = hide;
    });

    return appointments;
  };

  const [containerRef] = useInView({
    unobserveOnEnter: true,
    onEnter: () => {
      const { user }: { user?: TUser } = store.getState();
      const refreshRate = 10 * 60 * 1000; // 10mins * 60s * 1000ms

      const asyncInitAction = async (): Promise<void> => {
        fetchAppointmentList()
          .then(validateResponse)
          .then(setFirstConsultationList)
          .catch(e => console.error(e.error.title));
      };
      asyncInitAction();

      if (user?.roles?.includes(ERoles.MakeupArtist)) {
        refreshInit = setInterval(asyncInitAction, refreshRate);
      }
    }
  });

  useEffect(() => {
    return (): void => {
      refreshInit && clearInterval(refreshInit);
    };
  }, []);

  return (
    <div ref={containerRef}>
      {showLocations && (
        <Styled.ConsultationHeader>
          <Styled.LocationContainer>
            {locations?.map(el => (
              <Styled.Location
                key={el.id}
                active={locations.length > 1 ? el.id === location?.id : true}
                onClick={(): void => setLocation(el)}
              >
                {el.name}
              </Styled.Location>
            ))}
          </Styled.LocationContainer>
          {locations.length > 1 && (
            <Styled.ViewAll onClick={(): void => setLocation(null)}>
              VIEW ALL
            </Styled.ViewAll>
          )}
        </Styled.ConsultationHeader>
      )}
      {showFilters && (
        <OxPatientsFilters
          appointments={firstConsultation}
          setFilters={setFilters}
        />
      )}
      {firstConsultation?.length === 0 && (
        <Styled.EmptyDay>No appointments booked for today</Styled.EmptyDay>
      )}
      {firstConsultation?.length > 0 &&
        filteredFirstConsultation().filter(appointment => !appointment.hide)
          .length === 0 && (
          <Styled.EmptyDay>
            No appointments match your filter selection
          </Styled.EmptyDay>
        )}
      <Styled.OxPatientsSliderContainer>
        {filteredFirstConsultation()
          .sort(sortAppointments)
          .map(consultation => (
            <OxPatientsSliderItem
              key={consultation.id}
              consultation={consultation}
              extrasElement={extrasElement}
            />
          ))}
      </Styled.OxPatientsSliderContainer>
    </div>
  );
};
