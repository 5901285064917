import * as Styled from "./OxStaffDashboardDamagedProductsDoctor.styled";

import React, { Dispatch, SetStateAction, useContext } from "react";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import { TPractitioner } from "src/services/api/api.types";
import { OxSelect } from "src/components/OxSelect";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";

type TProps = {
  doctor?: TPractitioner | null;
  setDoctor: Dispatch<SetStateAction<TPractitioner | null>>;
};

export const OxStaffDashboardDamagedProductsDoctor = ({
  doctor,
  setDoctor
}: TProps): JSX.Element => {
  const { doctors } = useContext(WebsiteDataContext);

  return (
    <>
      {!doctor ? (
        <OxThemedInput theme={EInputTheme.Gold}>
          <OxSelect
            title="Select Doctor"
            items={doctors}
            labelName="label"
            valueName="id"
            onValueChange={(val: TPractitioner): void =>
              !!val && setDoctor(val)
            }
          />
        </OxThemedInput>
      ) : (
        <>
          Doctor Name:{" "}
          <Styled.DoctorName>
            {doctor?.title} {doctor?.firstName} {doctor?.lastName}
          </Styled.DoctorName>
        </>
      )}
    </>
  );
};
