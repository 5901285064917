import styled, { css } from "styled-components";
import { OxLink } from "src/components/OxLink";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";

export const Return = styled(OxLink)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary.main};
    ${fluidSizing([{ prop: "margin-bottom", values: [25, 25, 25] }])}
    svg {
      ${fluidSizing([
        { prop: "height", values: [5, 5, 5] },
        { prop: "margin-right", values: [5, 5, 5] },
        { prop: "margin-bottom", values: [2, 2, 2] }
      ])}
      transform: rotate(180deg);
    }
    span {
      text-transform: uppercase;
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.SemiBold,
        [10, 10, 10],
        [20, 20, 20]
      )}
    }
  `
);
