import * as Styled from "./OxTreatmentNotesZone.styled";

import React, {
  ChangeEvent,
  Dispatch,
  ReactFragment,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { OxSelect } from "src/components/OxSelect";
import { OxTable } from "src/components/OxTable";
import {
  TTreatmentInjectionPoint,
  TTreatmentNoteItem,
  TTreatmentOutcome,
  TTreatmentProduct,
  TTreatmentZone,
  TTreatmentZonePreferences,
} from "src/services/api/api.types";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { EOxInputType, OxInput } from "src/components/OxInput";
import {
  TreatmentNoteContext,
  TTreatmentNoteProductData,
} from "src/context/TreatmentNoteContext";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { isBotox, isHyalase } from "src/utils/treatmentNoteUtils";
import { OxAddRemoveButton } from "src/components/OxAddRemoveButton";

type TProductsSelected = {
  [key: string]: TTreatmentProduct | undefined;
};

type TProps = {
  existingZoneId: number;
  existingZoneItems: TTreatmentNoteItem[];
  generatedZoneKey: string;
  zoneIndex: number;
  zoneCount: number;
  zones: TTreatmentZone[];
  addZone: () => void;
  removeZone: (
    key: string,
    zoneId: number | undefined,
    isExisting?: boolean
  ) => void;
  selectedZoneIds: number[];
  setSelectedZoneIds: Dispatch<SetStateAction<number[]>>;
  clinicId: number;
};

type TTreatmentZoneOption = TTreatmentZone & {
  disabled?: boolean;
};

const columnTitles = ["Injection Points", "Products", "Volume"];

export const OxTreatmentNotesZone = ({
  existingZoneId,
  existingZoneItems,
  generatedZoneKey,
  zoneIndex,
  zoneCount,
  zones,
  addZone,
  removeZone,
  selectedZoneIds,
  setSelectedZoneIds,
  clinicId,
}: TProps): JSX.Element => {
  const { showAlert } = useContext(AlertContext);
  const {
    outcomeId,
    setProductDatum,
    setProductDatumVolume,
    treatmentGoals,
    locked,
  } = useContext(TreatmentNoteContext);
  const [selectedZone, setSelectedZone] = useState<number | undefined>(
    existingZoneId
  );

  const productsSelectedId = (id: number): string => `z${zoneIndex}p${id}`;
  const [productsSelected, setProductsSelected] = useState<TProductsSelected>(
    () => {
      const obj: TProductsSelected = {};
      existingZoneItems.forEach(
        (item) => (obj[productsSelectedId(item.product.id)] = item.product)
      );
      return obj;
    }
  );
  const [showOptions, setShowOptions] = useState<boolean>(
    zoneIndex > 0 || !!existingZoneId
  );

  const selectedZoneData = zones.find((zone) => zone.id === selectedZone);
  const productVolumes = [...Array(12)].map((_e, i) => {
    const volume = ((i + 1) / 10).toFixed(1);
    return { value: volume, label: `${volume}ml` };
  });

  const goal = treatmentGoals?.find((goal) =>
    goal.outcomes?.find((goalOutcome) => goalOutcome.id === outcomeId)
  );
  const outcome = goal?.outcomes?.find(
    (outcome: TTreatmentOutcome) => outcome.id === outcomeId
  );
  const primaryZones: TTreatmentZone[] =
    outcome?.zonePreferences?.map(
      (preference: TTreatmentZonePreferences) => preference.zone
    ) ?? [];
  const secondaryZones = zones.filter(
    (zone) => !primaryZones.map((item) => item.id).includes(zone.id)
  );

  /**
   * So that only selected product rows are sent to server, we only generate name if product has been selected
   * @param name
   * @param index
   * @param id
   */
  const getInputName = (name: string, index: number, id: number): string =>
    productsSelected[productsSelectedId(id)]
      ? `items.${zoneIndex + 1}0${index}.${name}`
      : "";

  const generateZoneRowArray = (
    point: TTreatmentInjectionPoint,
    index: number
  ): [ReactFragment, ReactFragment, ReactFragment] => {
    /**
     * 3 element array:
     *   Injection point name w/hidden inputs for zone ID, injection point ID
     *   Select input for product
     *   Select input for vol
     *
     */

    // inject existing product into locked notes incase it has been disabled
    const itemOptions = [
      { name: "", id: "" },
      ...(selectedZoneData?.products?.filter((product) =>
        product.clinics.includes(clinicId)
      ) ?? []),
    ];
    if (locked) {
      itemOptions.push({
        name: existingZoneItems.find(
          (item) => item.injectionPoint.id === point.id
        )?.product.name,
        id: existingZoneItems.find(
          (item) => item.injectionPoint.id === point.id
        )?.product.id,
      });
    }

    return [
      <>
        {point.name}
        <OxInput
          title="Zone ID"
          type={EOxInputType.Hidden}
          name={getInputName("zone", index, point.id)}
          value={selectedZoneData?.id}
        />
        <OxInput
          title="Injection Point ID"
          type={EOxInputType.Hidden}
          name={getInputName("injectionPoint", index, point.id)}
          value={point.id}
        />
      </>,

      <OxSelect
        key={index}
        name={getInputName("product", index, point.id)}
        onValueChange={(val: TTreatmentProduct | undefined): void => {
          setProductDatum({
            zoneId: selectedZone,
            injectionPointId: point.id,
            volume:
              existingZoneItems.find(
                (item) => item.injectionPoint.id === point.id
              )?.volume ?? undefined,
            name: val?.name,
            id: val?.id,
          } as TTreatmentNoteProductData);

          setProductsSelected((prev) => {
            prev[productsSelectedId(point.id)] = val;
            return prev;
          });
        }}
        labelName="name"
        valueName="id"
        initialValue={
          existingZoneItems.find((item) => item.injectionPoint.id === point.id)
            ?.product
        }
        items={itemOptions}
        disabled={locked}
      />,
      isBotox(productsSelected[productsSelectedId(point.id)]?.id ?? 0) ||
      isHyalase(productsSelected[productsSelectedId(point.id)]?.id ?? 0) ? (
        <OxInput
          type={EOxInputType.Number}
          key={index}
          required={!!productsSelected[productsSelectedId(point.id)]?.id}
          name={getInputName("volume", index, point.id)}
          initialValue={parseInt(
            existingZoneItems.find(
              (item) => item.injectionPoint.id === point.id
            )?.volume ?? "0"
          )}
          onChange={(e: ChangeEvent): void => {
            const target = e.target as HTMLInputElement;
            setProductDatumVolume(
              selectedZone ?? 0,
              point.id,
              parseInt(target.value)
            );
          }}
          min={1}
          max={
            isHyalase(productsSelected[productsSelectedId(point.id)]?.id ?? 0)
              ? 1500
              : 200
          }
          suppressCustomValidationMessage
          disabled={locked}
        />
      ) : (
        <OxSelect
          title="0.0ml"
          key={index}
          required={!!productsSelected[productsSelectedId(point.id)]?.id}
          name={getInputName("volume", index, point.id)}
          initialValue={productVolumes.find(
            (volume) =>
              volume.value ===
              parseFloat(
                existingZoneItems.find(
                  (item) => item.injectionPoint.id === point.id
                )?.volume ?? "0"
              ).toFixed(1)
          )}
          items={productVolumes}
          valueName="value"
          labelName="label"
          disabled={locked}
          onValueChange={(
            value: { value: string; label: string } | undefined
          ): void => {
            if (value) {
              setProductDatumVolume(
                selectedZone ?? 0,
                point.id,
                parseFloat(value.value)
              );
            }
          }}
        />
      ),
    ];
  };

  const getRowsToHideOnPrint = (): number[] => {
    const ret: number[] = [];
    selectedZoneData?.injectionPoints?.forEach(
      (point: TTreatmentInjectionPoint, index: number) => {
        if (
          !existingZoneItems.find((item) => item.injectionPoint.id === point.id)
        ) {
          ret.push(index);
        }
      }
    );
    return ret;
  };

  const shouldShowAllZones = (): boolean => {
    const isGoalRefinement =
      false && goal?.title.toLowerCase() === "refinement";
    const secondaryZoneSelectionCount = secondaryZones.filter((zone) =>
      selectedZoneIds.includes(zone.id)
    ).length;

    return !isGoalRefinement && secondaryZoneSelectionCount < 2;
  };

  /**
   * Preferential zones
   * divider
   * rest of zones
   *
   * Filtered to remove already selected zones
   */
  const getSortedAvailableZones = (): TTreatmentZone[] => {
    let availableZones: TTreatmentZoneOption[];

    if (primaryZones.length > 0) {
      availableZones = [...primaryZones];

      if (shouldShowAllZones()) {
        availableZones.push(
          { id: 0, disabled: true, title: "---------------" },
          ...secondaryZones
        );
      }
    } else {
      availableZones = zones;
    }

    return [
      { id: 0, title: "" } as TTreatmentZone,
      ...availableZones.filter(
        (item) => !Object.values(selectedZoneIds).includes(item.id)
      ),
    ];
  };

  return (
    <>
      {showOptions && (
        <Styled.Container>
          {selectedZone ? (
            <>
              <Styled.Header>
                <Styled.Text>
                  Zone {zoneIndex + 1}: {selectedZoneData?.title}
                </Styled.Text>
                {!locked && (
                  <Styled.RemoveZone
                    type="button"
                    onClick={(): void => {
                      showAlert({
                        type: EAlertVariant.Confirm,
                        message: "Are you sure you want to remove this zone?",
                        hideAfterConfirm: true,
                        onConfirm: () => {
                          removeZone(
                            generatedZoneKey,
                            selectedZoneData?.id,
                            !!existingZoneId
                          );
                        },
                      });
                    }}
                  >
                    <Styled.RemoveIcon name="plus-in-circle" size={30} /> Remove
                    zone
                  </Styled.RemoveZone>
                )}
              </Styled.Header>
              <OxTable
                columnTitles={columnTitles}
                rows={
                  selectedZoneData?.injectionPoints?.map(
                    (point: TTreatmentInjectionPoint, index: number) =>
                      generateZoneRowArray(point, index)
                  ) ?? [[]]
                }
                rowsToHideOnPrint={getRowsToHideOnPrint()}
              />
            </>
          ) : (
            <OxThemedInput theme={EInputTheme.Gold}>
              <Styled.Select
                onValueChange={(value: TTreatmentZone): void => {
                  if (typeof value !== "undefined") {
                    setSelectedZone(value.id);
                    setSelectedZoneIds((prev) => {
                      prev[zoneIndex] = value.id;
                      return prev;
                    });
                  }
                }}
                labelName="title"
                valueName="id"
                items={getSortedAvailableZones()}
                disabled={locked}
              />
            </OxThemedInput>
          )}
        </Styled.Container>
      )}
      {!locked &&
        ((!!selectedZone && zoneIndex + 1 === zoneCount) ||
          (zoneIndex === 0 && !showOptions)) && (
          <OxAddRemoveButton
            onClick={(): void => {
              zoneIndex === 0 && setShowOptions(true);
              (zoneIndex > 0 || showOptions) && addZone();
            }}
            size={50}
          >
            Add Zone {(zoneIndex > 0 || showOptions) && zoneIndex + 2}
          </OxAddRemoveButton>
        )}
    </>
  );
};
