import * as Styled from "./ProductIdentifier.styled";

import React, { ChangeEvent, useState } from "react";
import { EOxInputType, OxInput } from "src/components/OxInput";

type TProps = {
  lineKey: string;
  index: number;
  validateBatch: (barcode: string, key: string) => void;
};

export const ProductIdentifier = ({
  lineKey,
  index,
  validateBatch
}: TProps): JSX.Element => {
  const [barcode, setBarcode] = useState<string | undefined>();
  const [batch, setBatch] = useState<string | undefined>();
  const sharedConfig = {
    required: true,
    type: EOxInputType.Text,
    suppressCustomValidationMessage: true,
    onBlur: (): void => {
      !!barcode && validateBatch(barcode, lineKey);
    }
  };

  return (
    <>
      <Styled.BarcodeInput
        name={`${index}.barcode`}
        placeholder="Barcode*"
        value={barcode}
        onChange={(e: ChangeEvent): void => {
          const target = e.target as HTMLInputElement;

          setBarcode(target.value);
        }}
        {...sharedConfig}
      />
      <OxInput
        name={`${index}.batch`}
        placeholder="Batch*"
        value={batch}
        onChange={(e: ChangeEvent): void => {
          const target = e.target as HTMLInputElement;

          setBatch(target.value);
        }}
        {...sharedConfig}
      />
    </>
  );
};
