import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  & > *:first-child {
    opacity: 0;
  }
`;

export const Text = styled.p``;
