import React from "react";
import { RouteComponentProps } from "@reach/router";

import { OxPanelCustomerDashboard } from "src/panel/components/OxPanelCustomerDashboard";
import { OxPrivateRoute } from "src/panel/components/OxPrivateRoute/OxPrivateRoute";

export const PanelCustomerDashboard = (
  props: RouteComponentProps
): JSX.Element => {
  return <OxPrivateRoute component={OxPanelCustomerDashboard} />;
};
