import {navigate} from "@reach/router";
import {clearAuthError, passwordlessAuthFlowCheck, setAuthProcessStatus} from "src/panel/store/auth/authActions";
import {useDispatch, useStore} from "react-redux";
import {AlertContext, EAlertVariant} from "src/context/AlertContext";
import {getAuthError} from "src/panel/store/auth/authSelectors";
import {EAuthProcessStatus} from "src/panel/store/types";
import {useContext, useEffect} from "react";
import {ERoutes} from "src/config/enums";
import {isUserAdmin} from "src/helpers/user";

export const PanelPasswordlessLoginCheck = (): JSX.Element => {
    const urlParams = new URLSearchParams(window.location.search);

    const dispatch = useDispatch();
    const store = useStore();
    const { showAlert } = useContext(AlertContext);

    useEffect(() => {
        (async (urlParams: any): Promise<void> => {
            const result = await dispatch(
                passwordlessAuthFlowCheck({
                    user: urlParams.get("user"),
                    expires: urlParams.get("expires"),
                    hash: urlParams.get("hash")
                })
            );

            if (result.success) {
                const { user } = store.getState();
                if (await isUserAdmin(user)) {
                    navigate(ERoutes.PanelStaffLanding);
                } else {
                    navigate(ERoutes.PanelCustomerDashboard);
                }

                dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
                return;
            }

            const error = getAuthError(store.getState());
            if (error.length > 0) {
                dispatch(clearAuthError());
                dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
                showAlert({
                    type: EAlertVariant.Confirm,
                    title: "Login Failed",
                    sentryIgnore: true,
                    message:
                        "Please try again as the link may have timed out or has already been used",
                    onConfirm: () => {
                        console.log('confirmed');
                        navigate(ERoutes.PanelCustomerLogin);
                    },
                    onlyConfirm: true,
                    hideAfterConfirm: true,
                });
            }
        })(urlParams);
    }, []);
};
