import React, { useContext } from "react";
import * as Styled from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPatient/components/OxStaffDashboardPatientInvoices/OxStaffDashboardPatientInvoices.styled";
import { TPatientProfileInvoice } from "src/services/api/api.types";
import { OxLink } from "src/components/OxLink";
import { getUrl } from "src/services/api/api.core";
import { applyValues } from "src/utils/applyValues";
import { EApiEndpoint } from "src/config/enums";
import { DateTime } from "luxon";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";

type TProps = {
  invoices: TPatientProfileInvoice[];
  patientId: string;
};

export const OxStaffDashboardPatientInvoices = ({
  invoices,
  patientId,
}: TProps): JSX.Element => {
  const websiteDataContext = useContext(WebsiteDataContext);

  const currencySymbol = (clinicId: number): string =>
    websiteDataContext.locations.find((item) => `${item.id}` === `${clinicId}`)
      ?.currency ?? "";

  return (
    <Styled.Invoices>
      <Styled.Title>Invoices</Styled.Title>
      {invoices?.length > 0 ? (
        <Styled.Table>
          <thead>
            <tr>
              <Styled.THeadCell align="left">Invoice #</Styled.THeadCell>
              <Styled.THeadCell>Doctor</Styled.THeadCell>
              <Styled.THeadCell>Amount</Styled.THeadCell>
              <Styled.THeadCell>Date</Styled.THeadCell>
              <Styled.THeadCell>Download/Print</Styled.THeadCell>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <Styled.TCell align="left">
                  {invoice.number}({invoice.id})
                </Styled.TCell>
                <Styled.TCell>{invoice.practitionerName}</Styled.TCell>
                <Styled.TCell>
                  {currencySymbol(invoice.clinicId) + invoice.totalAmount}
                </Styled.TCell>
                <Styled.TCell>
                  {DateTime.fromISO(invoice.issueDate).toFormat("dd MMM yyyy")}
                </Styled.TCell>
                <Styled.TCell align="right">
                  {!!invoice.appointmentId && !!invoice.clinicId && (
                    <OxLink
                      target="_blank"
                      to={getUrl(
                        applyValues(EApiEndpoint.InvoicePatient, {
                          patientId,
                          clinicId: invoice.clinicId,
                          invoiceId: invoice.id,
                        })
                      )}
                    >
                      Download/Print
                    </OxLink>
                  )}
                </Styled.TCell>
              </tr>
            ))}
          </tbody>
        </Styled.Table>
      ) : (
        <Styled.NoInvoices>No Invoices Created</Styled.NoInvoices>
      )}
      <Styled.PrintMessage>
        NB. The Download/Print link will only appear when the invoice has been
        correctly created i.e. it is associated with an appointment in Cliniko.
      </Styled.PrintMessage>
    </Styled.Invoices>
  );
};
