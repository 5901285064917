import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${theme.colors.primary.main};
    ${fluidSizing([
      { prop: "width", values: [600, 600, 600] },
      { prop: "padding-bottom", values: [15, 15, 15] },
      { prop: "padding-top", values: [15, 15, 15] }
    ])}
    &:last-child {
      border-bottom: 1px solid ${theme.colors.primary.main};
    }
  `
);

export const NameWithIdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fluidSizing([{ prop: "margin-right", values: [5, 5, 5] }])}
`;

export const FullName = styled.div`
  text-transform: uppercase;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [13, 13, 13],
    [16, 16, 16]
  )}
  ${fluidSizing([{ prop: "margin-right", values: [5, 5, 5] }])}
`;

export const PatientId = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [13, 13, 13],
    [16, 16, 16]
  )}
`;
