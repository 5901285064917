import React, { useState } from "react";
import * as Styled from "./OxRefreshmentsSelect.styled";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";

export type TRefreshmentsSelectItem = {
  label: string;
  image?: string;
  fluidImages?: any;
};

type TRefreshmentsSelect = {
  items: TRefreshmentsSelectItem[];
  initialValue?: TRefreshmentsSelectItem;
  label: string;
  grid: number;
  onItemSelect?: (value: string) => void;
};

export const OxRefreshmentsSelect = (
  props: TRefreshmentsSelect
): JSX.Element => {
  const [
    selectValue,
    setSelectValue
  ] = useState<TRefreshmentsSelectItem | null>(
    props.initialValue ? props.initialValue : null
  );
  const [isOpen, setIsOpen] = useState(false);

  const onItemClick = (item: TRefreshmentsSelectItem) => {
    if (item.label === selectValue?.label) {
      setSelectValue(null);
      props.onItemSelect("");
    } else {
      setSelectValue(item);
      props.onItemSelect(item.label);
    }
    setIsOpen(false);
  };

  return (
    <Styled.DropDownContainer>
      <OxThemedInput theme={EInputTheme.BackgroundWhite}>
        <Styled.CustomSelect icon onClick={() => setIsOpen(prev => !prev)}>
          {selectValue !== null ? selectValue?.label : props.label}
        </Styled.CustomSelect>
      </OxThemedInput>
      <Styled.DropDownListContainer count={props.grid} isOpen={isOpen}>
        {props.items.map((el, index) => (
          <Styled.DropDownItem
            key={index}
            onClick={() => onItemClick(el)}
            rightBorder={(index + 1) % props.grid !== 0}
          >
            <Styled.CustomImage
              fluidImages={el.fluidImages}
              image={el.image}
              loading="auto"
            />
            <Styled.HorizontalLine />
            <Styled.ItemLabel>{el.label}</Styled.ItemLabel>
          </Styled.DropDownItem>
        ))}
      </Styled.DropDownListContainer>
    </Styled.DropDownContainer>
  );
};
