import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { OxLink } from "src/components/OxLink";
import { transparentize } from "polished";
import { TArchetype } from "src/services/api/api.types";

export const Container = styled.div(
  ({ theme }) => css`
    align-items: center;
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 100;
    background: ${transparentize(0.1, theme.colors.basic.white)};
    ${fluidSizing([
      { prop: "height", values: [65, 65, 65] },
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}
  `
);

export const Logo = styled(OxLink)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    flex: 1 1 50%;

    ${fluidSizing([{ prop: "height", values: [15, 15, 15] }])}

    svg {
      height: 100%;
      width: auto;
    }
  `
);

export const Details = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
`;

export const Border = styled.div(
  ({ theme }) => css`
    align-self: flex-start;
    background: ${theme.colors.basic.black};
    flex: 1 1 100%;
    height: 1px;

    &::before {
      background: ${theme.colors.basic.black};
      content: "";
      display: block;
      transform: translateY(calc(-100% + 1px));

      ${fluidSizing([
        { prop: "height", values: [3, 4, 4] },
        { prop: "width", values: [200, 200, 200] }
      ])}
    }
  `
);

export const Header = styled.h1`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Normal,
    [14, 14, 14],
    [20, 20, 20]
  )}
`;

export const PatientProfile = styled.div(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.colors.basic.silverChalice};
    display: flex;

    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Normal,
      [10, 10, 10],
      [8, 8, 8]
    )}

    ${fluidSizing([{ prop: "margin-top", values: [5, 5, 5] }])}
  `
);

export const Type = styled.span<{ archetype: TArchetype }>(
  ({ theme, archetype }) => css`
    background-color: ${theme.colors.archetype[archetype]};
    border-radius: 100%;
    display: inline-block;

    ${fluidSizing([
      { prop: "height", values: [10, 10, 10] },
      { prop: "margin-left", values: [5, 5, 5] },
      { prop: "margin-right", values: [5, 5, 5] },
      { prop: "width", values: [10, 10, 10] }
    ])}
  `
);
