import * as Styled from "./OxCustomerDashboardMyTreatment.styled";

import React, { useState } from "react";

import { TTreatmentPlan } from "src/services/api/api.types";
import { getPatientTreatmentplan } from "src/services/api/patient";
import { OxPanelViewTreatmentPlans } from "src/panel/components/OxPanelViewTreatmentPlans";
import { RouteComponentProps } from "@reach/router";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxCustomerDashboardMyTreatment: React.FC<
  RouteComponentProps
> = () => {
  const { validateResponse } = useValidateResponse();
  const [details, setDetails] = useState<TTreatmentPlan[]>([]);

  const fetchData = async (): Promise<void> => {
    const data = await getPatientTreatmentplan();
    try {
      validateResponse(data);
    } catch (e) {
      console.error(e.error.title);
      return;
    }

    if (data) setDetails(data);
  };

  return (
    <Styled.Container onInit={fetchData}>
      <OxPageHelmet title="My Treatment Plan" />
      {details.length > 0 ? (
        <OxPanelViewTreatmentPlans isPatient={true} />
      ) : (
        <Styled.Paragraph>{`You don't yet have a treatment plan.`}</Styled.Paragraph>
      )}
    </Styled.Container>
  );
};
