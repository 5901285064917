import { EAuthProcessStatus, TAuthError, TStore } from "../types";
import { removeUser, setUser } from "../user/userActions";
import {
  twoFactorAuthCheck,
  userGetUser,
  userPostLogin, userPostLoginPasswordless, userPostLoginPasswordlessCheck,
} from "src/services/api/user";

import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { createAction } from "redux-act";
import { TBookingFlowContext } from "src/context/BookingFlowContext";
import { clearSecureCache } from "src/services/api/api.cache";

export const setLoggedIn = createAction("setLoggedIn");
export const setLoggedOut = createAction("setLoggedOut");
export const setAuthProcessStatus = createAction<EAuthProcessStatus>(
  "setAuthProcessStatus"
);
export const setAuthError = createAction<TAuthError>("setAuthError");
export const clearAuthError = createAction("clearAuthError");

type TPasswordAuthFlowPayload = {
  username: string;
  password: string;
};

type TPasswordlessAuthFlowPayload = {
  username: string;
};

type TPasswordlessAuthFlowCheckPayload = {
  user: string;
  expires: string;
  hash: string;
};

type ThunkResult<R> = ThunkAction<R, TStore, unknown, AnyAction>;

export const passwordAuthFlow = (
  payload: TPasswordAuthFlowPayload
): ThunkResult<Promise<{ success?: boolean; loggedIn?: boolean }>> => {
  return async (
    dispatch,
    getState
  ): Promise<{ success?: boolean; loggedIn?: boolean }> => {
    const state = getState();
    if (state.auth.authProcessStatus !== EAuthProcessStatus.Inaction) return {};
    dispatch(setAuthProcessStatus(EAuthProcessStatus.Started));
    // TODO: Clear logged in user data
    dispatch(setAuthProcessStatus(EAuthProcessStatus.PasswordProcessing));
    try {
      const result = await userPostLogin(payload);
      if (!result?.login) throw new Error("Incorrect email or password");
      //no 2fa for staff login
        localStorage.setItem("ornx_user_token", result?.token as string);
        clearSecureCache();
        const user = await userGetUser();
        if (!user || !user.email) throw new Error("Something went wrong");
        dispatch(setUser(user));
        dispatch(setAuthProcessStatus(EAuthProcessStatus.Success));
        return { success: true, loggedIn: true };
    } catch (err) {
      dispatch(setAuthProcessStatus(EAuthProcessStatus.Error));
      dispatch(setAuthError([err.message]));
      return { success: false };
    }
  };
};

export const passwordlessAuthFlow = (
  payload: TPasswordlessAuthFlowPayload
): ThunkResult<Promise<{ success?: boolean; }>> => {
  return async (
    dispatch,
    getState
  ): Promise<{ success?: boolean; }> => {
    const state = getState();
    if (state.auth.authProcessStatus !== EAuthProcessStatus.Inaction) return {};
    dispatch(setAuthProcessStatus(EAuthProcessStatus.Started));
    // TODO: Clear logged in user data
    dispatch(setAuthProcessStatus(EAuthProcessStatus.PasswordProcessing));
    try {
      const result = await userPostLoginPasswordless(payload);
      if (!result?.login_link) throw new Error("Incorrect email or password");

      dispatch(setAuthProcessStatus(EAuthProcessStatus.PasswordlessSent));
      return {success: true};
    } catch (err) {
      dispatch(setAuthProcessStatus(EAuthProcessStatus.Error));
      dispatch(setAuthError([err.message]));
      return {success: false};
    }
  };
};

export const passwordlessAuthFlowCheck = (
    payload: TPasswordlessAuthFlowCheckPayload
): ThunkResult<Promise<{ success?: boolean; loggedIn?: boolean }>> => {
  return async (
      dispatch,
      getState
  ): Promise<{ success?: boolean; loggedIn?: boolean }> => {
    const state = getState();
    if (state.auth.authProcessStatus !== EAuthProcessStatus.Inaction) return {};
    dispatch(setAuthProcessStatus(EAuthProcessStatus.Started));
    // TODO: Clear logged in user data
    dispatch(setAuthProcessStatus(EAuthProcessStatus.PasswordProcessing));
    try {
      const result = await userPostLoginPasswordlessCheck(payload);

      if (result.login) {
        localStorage.setItem("ornx_user_token", result?.token as string);
        clearSecureCache();
        const user = await userGetUser();
        if (!user || !user.email) throw new Error("Something went wrong");
        dispatch(setUser(user));
        dispatch(setAuthProcessStatus(EAuthProcessStatus.Success));
        return { success: true, loggedIn: true };
      } else {
        throw new Error("Please try again");
      }
    } catch (err) {
      dispatch(setAuthProcessStatus(EAuthProcessStatus.Error));
      dispatch(setAuthError([err.message]));
      return {success: false};
    }
  };
};

export function codeVrificationAuthFlow(
  payload: FormData
): ThunkResult<Promise<{ success?: boolean; loggedIn?: boolean }>> {
  return async (
    dispatch,
    getState
  ): Promise<{ success?: boolean; loggedIn?: boolean }> => {
    const state = getState();
    if (state.auth.authProcessStatus !== EAuthProcessStatus.Verification)
      return {};
    dispatch(setAuthProcessStatus(EAuthProcessStatus.VerificationProcessing));
    try {
      const result = await twoFactorAuthCheck(payload);
      if (!result["2fa_complete"] || result.error)
        throw new Error(result.error || "Something went wrong");
      localStorage.setItem("ornx_user_token", result?.token as string);
      const user = await userGetUser();
      if (!user || !user.email) throw new Error("Something went wrong");
      dispatch(setUser(user));
      dispatch(setAuthProcessStatus(EAuthProcessStatus.Success));
      return { success: true, loggedIn: true };
    } catch (err) {
      dispatch(setAuthProcessStatus(EAuthProcessStatus.Error));
      dispatch(setAuthError([err.message]));
      return { success: false };
    }
    // TODO: Redirect to dashboard
  };
}

export function logoutFlow(
  bookingFlowContext?: TBookingFlowContext
): ThunkResult<Promise<{ success?: boolean; loggedIn?: boolean }>> {
  return async (
    dispatch
  ): Promise<{ success?: boolean; loggedIn?: boolean }> => {
    localStorage.removeItem("ornx_user_token");
    dispatch(removeUser());
    dispatch(setLoggedOut());
    dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
    bookingFlowContext?.reset();
    clearSecureCache();

    return { success: true };
  };
}
