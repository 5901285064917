import styled, { css } from "styled-components";

import { OxIcon } from "../OxIcon";
import { fluidSizing } from "src/utils";

export const Container = styled.div`
  display: flex;
  align-items: center;
  & > * {
    ${fluidSizing([
      { prop: "width", values: [20, 20, 20] },
      { prop: "height", values: [18, 18, 18] }
    ])}
  }
`;

export const StarHalf = styled.div`
  position: relative;
  svg {
    position: absolute;
  }
`;

export const StartFilled = styled(OxIcon)<{ doClip?: boolean }>(
  ({ theme, doClip }) => css`
    path {
      fill: ${theme.colors.primary.main};
    }
    ${doClip &&
      css`
        clip-path: polygon(0% 0%, 54% 0, 54% 100%, 0 100%);
      `}
  `
);
export const StarEmpty = styled(OxIcon)<{ doClip?: boolean }>(
  ({ theme, doClip }) => css`
    path {
      fill: ${theme.colors.basic.white};
      stroke: ${theme.colors.primary.main};
    }
    ${doClip &&
      css`
        clip-path: polygon(54% 0%, 100% 0, 100% 100%, 54% 100%);
      `}
  `
);
