import * as Styled from "./OxStaffDashboardFeedback.styled";

import { OxRatingStars } from "src/components/OxRatingStars";
import React, { useEffect, useState } from "react";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { OxLink } from "src/components/OxLink";
import { getPractitionerFeedback } from "src/services/api/practitioner";
import { TFeedback } from "src/services/api/api.types";
import { ERoutes } from "src/config/enums";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { useInView } from "src/hooks/useInViewport";

type TProps = {
  id?: string;
  showAll?: boolean;
  preFetchedFeedback?: TFeedback[];
};

type TRating = {
  title: string;
  value: number;
};

export const OxStaffDashboardFeedback = ({
  id,
  preFetchedFeedback,
  showAll
}: TProps): JSX.Element => {
  const { validateResponse } = useValidateResponse();
  const [feedback, setFeedback] = useState<TFeedback[]>([]);
  const [error, setError] = useState<boolean>(false);

  const ratingsMap: { title: string; key: string }[] = [
    {
      title: "How did you like the medical consultation with the doctor?",
      key: "feedbackMedical"
    },
    {
      title: "How would you rate the outcome of your treatment?",
      key: "feedbackOutcome"
    }
  ];

  const getMappedRatings = (item: TFeedback): TRating[] => {
    return ratingsMap
      .map(({ key, title }) => ({
        title: title,
        value: item[key] as number
      }))
      .filter(item => item.value);
  };

  const getAverage = (): number => {
    return (
      feedback.reduce(
        (prev: number, curr: TFeedback) => prev + curr.averageRating,
        0
      ) / (feedback.length === 0 ? 1 : feedback.length)
    );
  };

  const [containerRef] = useInView({
    unobserveOnEnter: true,
    onEnter: () => {
      if (!preFetchedFeedback) {
        setError(false);
        getPractitionerFeedback()
          .then(validateResponse)
          .then(setFeedback)
          .catch(() => {
            setError(true);
          });
      }
    }
  });

  useEffect(() => {
    if (preFetchedFeedback) {
      setFeedback(preFetchedFeedback);
      return;
    }
  }, []);

  return (
    <Styled.Container id={id} ref={containerRef}>
      <Styled.Header>
        <Styled.HeaderText>Client Feedback</Styled.HeaderText>
        <Styled.OverallRating value={getAverage()} />
        <Styled.HeaderText>
          {((getAverage() * 10) / 6).toFixed(1)}
        </Styled.HeaderText>
      </Styled.Header>
      {error && <Styled.Error>Unable to load feedback</Styled.Error>}
      {!error &&
        feedback
          .filter((_item, index) => index === 0 || showAll)
          .map((item, index) => (
            <Styled.Feedback key={index} className="feedback">
              <Styled.RatingsContainer>
                {getMappedRatings(item).map(
                  (rating: TRating, index: number) => (
                    <Styled.RatingBox key={index}>
                      <Styled.RatingTitle>{rating.title}</Styled.RatingTitle>
                      <OxRatingStars value={rating.value} />
                    </Styled.RatingBox>
                  )
                )}
              </Styled.RatingsContainer>
              <Styled.ContentContainer>
                <Styled.ContentText>{item.feedbackComments}</Styled.ContentText>
                {item.initials && (
                  <Styled.InitialsText>
                    Initials: {item.initials}
                  </Styled.InitialsText>
                )}
                {!showAll && (
                  <OxThemedInput theme={EInputTheme.BackgroundGold}>
                    <Styled.ContentButton icon>
                      <OxLink
                        to={ERoutes.PanelStaffDashboardFeedback}
                        state={{ preFetchedFeedback: feedback }}
                      >
                        READ ALL REVIEWS
                      </OxLink>
                    </Styled.ContentButton>
                  </OxThemedInput>
                )}
              </Styled.ContentContainer>
            </Styled.Feedback>
          ))}
    </Styled.Container>
  );
};
