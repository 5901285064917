import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "@reach/router";

import { OxStaffDashboardMyDetails } from "../OxStaffDashboardMyDetails";
import { useStore } from "react-redux";
import { OxStaffDashboardRoot } from "../OxStaffDashboardRoot";
import scrollToId from "src/utils/scrollToId";
import { OxStaffDashboardRoles } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxStaffDashboardMyAccount: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const store = useStore();
  const { user } = store.getState();

  useEffect(() => {
    if (containerRef.current) {
      window.scrollTo(0, containerRef.current.offsetTop - 100);
    }
    if (location?.hash) {
      scrollToId(location.hash.replace(/^#/, ""));
    }
  }, []);

  return (
    <OxStaffDashboardRoot location={location}>
      <OxPageHelmet title="My Account" />
      <OxStaffDashboardMyDetails
        id="details"
        image={user?.photo?.path}
        fullName={`${user?.title ? `${user?.title} ` : ``}${
          user?.firstname ? `${user?.firstname} ` : ``
        }${user?.lastname}`}
        phoneNumber={user?.mobile}
        email={user?.email}
      />
      <OxStaffDashboardRoles />
    </OxStaffDashboardRoot>
  );
};
