import { EApiEndpoint } from "src/config/enums";
import { TDamageReportItem, TDearProduct, TDearReason } from "./api.types";
import { apiGetCall, apiPostCall } from "./api.core";
import { applyValues } from "src/utils/applyValues";

export const getAllProducts = async (
  clinicId: string
): Promise<TDearProduct[] | null> => {
  return await apiGetCall(
    applyValues(EApiEndpoint.DearProducts, { clinicId }),
    {
      credentials: true
    }
  );
};

export const getProduct = async (values: {
  barcode: string;
  clinicId: string;
}): Promise<TDearProduct[] | null> => {
  return await apiGetCall(applyValues(EApiEndpoint.DearProduct, values), {
    credentials: true
  });
};

export const getReasons = async (): Promise<TDearReason[] | null> => {
  return await apiGetCall(EApiEndpoint.DearReasons, {
    credentials: true
  });
};

export const postDamageReport = async (
  values: { practitionerId: string | false },
  body: TDamageReportItem[]
): Promise<null> => {
  return await apiPostCall(
    values.practitionerId
      ? applyValues(EApiEndpoint.DearProductDamagedByPractitioner, values)
      : EApiEndpoint.DearProductDamaged,
    JSON.stringify(body),
    { credentials: true }
  );
};
