import * as Styled from "./OxCreateAccount.styled";

import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { EOxInputType, OxInput } from "src/components/OxInput";
import React, { useContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { EModalId } from "src/components/OxHygene";
import { ERoutes } from "src/config/enums";
import { LayoutContext } from "src/context/LayoutContext";
import { OxButton } from "src/components/OxButton";
import { OxCheckbox } from "src/components/OxCheckbox";
import { OxSelect } from "src/components/OxSelect";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import { navigate } from "@reach/router";
import { useBreakpoints } from "src/hooks";
import { userCreateAccount } from "src/services/api/user";
import { EAlertVariant } from "src/context/AlertContext";
import { OnyxError } from "src/helpers/OnyxError";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxCreateAccount = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "panel/login_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const [processing, setProcessing] = useState(false);
  const device = useBreakpoints();
  const websiteContextData = useContext(WebsiteDataContext);
  const layoutContext = useContext(LayoutContext);

  const dropdownItems = websiteContextData.locations.map((item) => ({
    label: item.displayName,
    value: item.id,
  }));

  const onFormSubmit = async (data: FormData): Promise<void> => {
    setProcessing(true);
    const createAccountResponse = await userCreateAccount({
      clinicId: data.get("clinic") as string,
      firstname: data.get("first_name") as string,
      lastname: data.get("last_name") as string,
      email: data.get("email") as string,
      password: data.get("password") as string,
      mobile: data.get("mobile") as string,
      newsletter: !!data.get("newsletter"),
      acceptedPrivacyPolicy: !!data.get("privacy_policy"),
    });

    if (createAccountResponse?.id && createAccountResponse?.apiToken) {
      localStorage.setItem(
        "ornx_user_token",
        createAccountResponse?.apiToken as string
      );
      navigate(ERoutes.PanelCustomerDashboard);
    } else if (createAccountResponse?.error) {
      setProcessing(false);
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT: OCA1",
        title: "Something went wrong",
        message:
          "Please check your details and try again, if the problem persists please contact us.",
      });
    }
    if (processing) {
      setTimeout(() => {
        setProcessing(false);
      }, 1500);
    }
  };

  return (
    <Styled.Container
      fluidImages={data.backgroundImage.childImageSharp.gatsbyImageData}
      asBackground
    >
      <OxPageHelmet title="Create an Account" />
      <Styled.Box>
        <Styled.Label>CREATE</Styled.Label>
        <Styled.Title>Client Account</Styled.Title>
        {processing && <Styled.Spinner />}
        {!processing && (
          <Styled.Form className="create-account" onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
              <>
                <OxThemedInput theme={EInputTheme.GoldTransparentAlternative}>
                  {dropdownItems.length === 1 && (
                    <input
                      type={EOxInputType.Hidden}
                      name="clinic"
                      value={dropdownItems[0].value}
                      required
                    />
                  )}
                  {dropdownItems.length > 1 && (
                    <OxSelect
                      name="clinic"
                      title="Select Clinic"
                      labelName="label"
                      valueName="value"
                      items={dropdownItems}
                      required
                    />
                  )}
                  <OxInput
                    type={EOxInputType.Text}
                    name="first_name"
                    placeholder="First Name*"
                    required
                  />
                  <OxInput
                    type={EOxInputType.Text}
                    name="last_name"
                    placeholder="Last Name*"
                    required
                  />
                  <OxInput
                    type={EOxInputType.Email}
                    name="email"
                    placeholder="Email*"
                    required
                  />
                  <OxInput
                    type={EOxInputType.Tel}
                    name="mobile"
                    placeholder="Mobile Number*"
                    required
                  />
                  <OxInput
                    type={EOxInputType.Password}
                    name="password"
                    placeholder="Password*"
                    required
                  />
                  <OxCheckbox
                    id="newsletter"
                    name="newsletter"
                    value="newsletter"
                    label="Subscribe to Newsletter"
                    initialChecked
                  />
                  <OxCheckbox
                    id="privacy_policy"
                    name="privacy_policy"
                    value="privacy_policy"
                    label="Accept"
                    labelLinkTitle="Privacy Policy"
                    labelLinkOnClick={(): void => {
                      layoutContext.setModalId(EModalId.Terms);
                    }}
                    required
                  />
                </OxThemedInput>
                <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                  <OxButton type="submit" icon {...submitButtonProps}>
                    CREATE ACCOUNT
                  </OxButton>
                </OxThemedInput>
              </>
            )}
          </Styled.Form>
        )}
      </Styled.Box>
    </Styled.Container>
  );
};
