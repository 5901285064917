import styled, { css } from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { OxButton } from "src/components/OxButton";
import { fluidSizing } from "src/utils";

export const Container = styled.section(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [10, 10, 10],
      [10, 10, 10]
    )};

    ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
  `
);
export const Heading = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.basic.black};
    display: flex;
    font-weight: ${EFontWeight.SemiBold};
    justify-content: space-between;

    ${fluidSizing([
      { prop: "margin-bottom", values: [10, 10, 10] },
      { prop: "padding-bottom", values: [10, 10, 10] }
    ])}
  `
);
export const Card = styled.div`
  text-transform: capitalize;
`;

export const Button = styled(OxButton)`
  border: unset;
  font-weight: ${EFontWeight.SemiBold};
  height: unset;
  line-height: 1;
  min-width: unset;
  padding: unset;
  width: unset;
`;

export const Body = styled.div``;
export const Expiry = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.basic.black};

    ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
  `
);
export const ExpiryDate = styled.span`
  font-weight: ${EFontWeight.SemiBold};
`;

export const Address = styled.address`
  font-style: normal;
`;
