import React, { ChangeEvent, useState } from "react";
import * as Styled from "./OxStaffPin.styled";
import { TFormChildCallbackProps } from "src/components/OxForm";
import { navigate, NavigateOptions } from "@reach/router";
import { STAFF_PIN } from "src/config/constants";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { EOxInputType } from "src/components/OxInput";

type TSuccessCallback = {
  successCallback: () => void;
};

type TSuccessNavigate = {
  successUrl: string;
  successNavigateOptions?: NavigateOptions<Record<string, unknown>>;
};

export type TOxStaffPinProps = (TSuccessCallback | TSuccessNavigate) & {
  heading?: string;
  description?: string;
  buttonCTA?: string;
  successCallback?: () => void;
};

enum PIN_ERROR {
  EMPTY = "Please enter the pin",
  INCORRECT = "Sorry, that pin is incorrect",
}

export const OxStaffPin = (props: TOxStaffPinProps): JSX.Element => {
  const {
    heading = "Thank You",
    description,
    buttonCTA = "Next",
    successCallback,
  } = props;

  const [pin, setPin] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onButtonClick = async (): Promise<void> => {
    if (pin === STAFF_PIN) {
      setErrorMessage("");
      if (successCallback) {
        successCallback();
      } else if ("successUrl" in props) {
        await navigate(props.successUrl, props.successNavigateOptions ?? {});
      }
    } else {
      setErrorMessage(pin.length > 0 ? PIN_ERROR.INCORRECT : PIN_ERROR.EMPTY);
    }
  };

  const onInputChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setPin(target.value);
  };

  return (
    <Styled.Container>
      {heading && <Styled.Title>{heading}</Styled.Title>}
      {description && <Styled.Description>{description}</Styled.Description>}
      <OxThemedInput theme={EInputTheme.BackgroundWhite}>
        <Styled.Input
          type={EOxInputType.Number}
          maxLength={4}
          pattern="\d*"
          value={pin}
          onChange={onInputChange}
          placeholder="Staff Pin"
        />
        <Styled.Button icon type="button" onClick={onButtonClick}>
          {buttonCTA}
        </Styled.Button>
        {errorMessage && <Styled.Error>{errorMessage}</Styled.Error>}
      </OxThemedInput>
    </Styled.Container>
  );
};
