import { RouteComponentProps } from "@reach/router";
import React from "react";
import { OxWelcomeInternalFlow } from "src/panel/components/OxWelcomeIntenalFlow";
import { OxPrivateRoute } from "src/panel/components/OxPrivateRoute";

export const PanelWelcomeInternal = (
  props: RouteComponentProps
): JSX.Element => {
  return (
    <OxPrivateRoute
      component={OxWelcomeInternalFlow}
      location={props.location}
    />
  );
};
