import React, {InputHTMLAttributes} from "react";
import * as Styled from "./OxCheckmarkCheckbox.styled";

export type TOxCheckmarkCheckbox = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  value: string;
  defaultChecked?: boolean;
  onValueChange?: (val: string) => void;
};

export const OxCheckmarkCheckbox = ({
  id,
  label,
  name,
  value,
  type,
  className,
  onValueChange,
  onClick,
                                      defaultChecked,
  ...props
}: SCProps<"input", TOxCheckmarkCheckbox>): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onValueChange && onValueChange(e.target.value);
  };
  return (
    <Styled.Container className={className} onClick={onClick}>
      <Styled.Input
        {...props}
        value={`${value}`}
        id={id}
        name={name}
        type={type || "checkbox"}
        defaultChecked={defaultChecked}
        onChange={handleChange}
      />
      <Styled.Label htmlFor={id}>
        <Styled.InputWithIconContainer>
          <Styled.CheckmarkIcon name="checkmark" />
        </Styled.InputWithIconContainer>
        {label}
      </Styled.Label>
    </Styled.Container>
  )
};
