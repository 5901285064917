import React from "react";

import { OxComponentContainer } from "src/components/OxComponentContainer";
import { OxStaffDashboardPatients } from "../../../OxStaffDashboardPatients";
import { OxStaffDashboardMessages } from "../../../OxStaffDashboardMessages";
import { EAppointmentsType } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments";

export const OxNurseDashboard = (): JSX.Element => {
  return (
    <>
      <OxComponentContainer
        marginTop={[40, 40, 40]}
        marginBottom={[40, 34, 50]}
      >
        <OxStaffDashboardPatients
          type={EAppointmentsType.LOCATION}
          id="patients"
        />
      </OxComponentContainer>
      <OxComponentContainer
        marginTop={[50, 50, 50]}
        marginBottom={[50, 50, 50]}
      >
        {/*<OxStaffDashboardMessages />*/}
      </OxComponentContainer>
    </>
  );
};
