import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxDashboardContentWrapper } from "../../../OxDashboardContentWrapper";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled(OxDashboardContentWrapper)``;

export const Header = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.basic.black};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [12, 12, 12],
      [20, 20, 20]
    )}
    p {
      display: flex;
      ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
    }
    span {
      color: ${theme.colors.primary.main};
      font-weight: ${EFontWeight.SemiBold};
      ${fluidSizing([{ prop: "margin-left", values: [5, 5, 5] }])}
    }
  `
);

export const Paragraph = styled.p`
  ${fluidSizing([{ prop: "padding-top", values: [20, 20, 20] }])}
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [12, 12, 12],
    [20, 20, 20]
  )}
`;
