import * as Styled from "./OxSignature.styled";

import React, { useRef } from "react";
import { OxButton } from "src/components/OxButton";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";

type TProps = {
  captureTriggerTitle: string;
  onCapture: (signature: string) => void;
};

export const OxSignature = ({
  captureTriggerTitle,
  onCapture,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  const sigCanvas = useRef<ReactSignatureCanvas>(null);

  const capture = (): void => {
    onCapture(sigCanvas?.current?.getTrimmedCanvas().toDataURL("image/png"));
  };

  return (
    <Styled.Container>
      <Styled.SignatureContainer>
        <SignatureCanvas ref={sigCanvas} canvasProps={{ height: 600 }} />
      </Styled.SignatureContainer>
      <Styled.Actions>
        <OxButton onClick={capture} icon>
          {captureTriggerTitle}
        </OxButton>
      </Styled.Actions>
    </Styled.Container>
  );
};
