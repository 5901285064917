import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { OxBackButton } from "src/components/OxBackButton";

export const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.basic.white};
    ${fluidSizing([
      { prop: "max-width", values: [390, 390, 390] },
      { prop: "padding", values: [28, 40, 40] },
      { prop: "margin", values: [28, 40, 40] }
    ])}
  `
);

export const Label = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [12, 12, 12],
      [15, 15, 15]
    )};
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
  `
);

export const Content = styled.div`
  position: relative;
  flex: 1;
`;

export const BackButton = styled(OxBackButton)`
  margin-left: auto;
  margin-right: auto;
  ${fluidSizing([
    { prop: "margin-bottom", values: [-15, -15, -15] },
    { prop: "margin-top", values: [15, 15, 15] }
  ])}
`;
