import * as Styled from "./OxPanelCustomerDashboard.styled";

import React, { useEffect, useState } from "react";
import { Router, navigate } from "@reach/router";

import { ERoutes } from "src/config/enums";
import { OxCustomerDashboardCommunicationPreferences } from "src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardCommunicationPreferences";
import { OxCustomerDashboardMyAccount } from "./components/OxCustomerDashboardMyAccount";
import { OxCustomerDashboardMyJourney } from "./components/OxCustomerDashboardMyJourney";
import { OxCustomerDashboardMyDetails } from "./components/OxCustomerDashboardMyDetails";
import { OxCustomerDashboardMyDoctor } from "./components/OxCustomerDashboardMyDoctor";
import { OxCustomerDashboardMyTreatment } from "./components/OxCustomerDashboardMyTreatment";
import { OxDashboardMenu } from "src/panel/components/OxDashboardMenu";
import { Panel404 } from "src/panel/routes/Panel404";
import { useStore } from "react-redux";
import { OxCustomerDashboardPastAppointments } from "./components/OxCustomerDashboardPastAppointments";
import { OxCustomerDashboardUpcomingAppointments } from "./components/OxCustomerDashboardUpcomingAppointments";
import { OxCustomerDashboardMyFiles } from "./components/OxCustomerDashboardMyFiles";
import { getPatientPersonalityArchetype } from "src/services/api/patient";
import { TUser } from "src/services/api/api.types";
import { isUserAdmin } from "src/helpers/user";
import { OxCustomerDashboardSavedPaymentDetails } from "src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardSavedPaymentDetails";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxPanelCustomerDashboard = (): JSX.Element => {
  const [psychologicalArchetype, setPsychologicalArchetype] = useState("");
  const [userDetails, setUserDetails] = useState<TUser | null>(null);

  const menuItems = [
    {
      title: "My Account",
      to: ERoutes.PanelCustomerDashboard,
    },
    // {
    //   title: "Saved Payment Details",
    //   to: ERoutes.PanelCustomerDashboardSavedPaymentDetails,
    //   subItem: true,
    // },
    {
      title: "Communication Preferences",
      to: ERoutes.PanelCustomerDashboardCommunicationPrefs,
      subItem: true,
    },
    {
      title: "Personality Archetype",
      to: psychologicalArchetype
        ? "/" + psychologicalArchetype
        : ERoutes.ArchetypesSurvey,
      state: userDetails,
      subItem: true,
    },
    {
      title: "My Journey",
      to: ERoutes.PanelCustomerDashboardMyJourney,
    },
    {
      title: "Upcoming Appointments",
      to: ERoutes.PanelCustomerDashboardUpcomingAppointments,
      subItem: true,
    },
    {
      title: "Past Appointments",
      to: ERoutes.PanelCustomerDashboardPastAppointments,
      subItem: true,
    },
    {
      title: "My Treatment Plan",
      to: ERoutes.PanelCustomerDashboardMyTreatmentPlan,
      subItem: true,
    },
    {
      title: "My Files",
      to: ERoutes.PanelCustomerDashboardMyFiles,
      subItem: true,
    },
    {
      title: "Logout",
      logout: true,
      subItem: true,
    },
  ];

  const store = useStore();
  useEffect(() => {
    let unmounted = false;
    const asyncInit = async (): Promise<void> => {
      const { user } = store.getState();
      setUserDetails(user);

      if (await isUserAdmin(user)) {
        await navigate(ERoutes.PanelStaffLogin);
        return;
      }

      getPatientPersonalityArchetype().then((data) => {
        if (!unmounted) {
          setPsychologicalArchetype(data && data.length > 0 ? data : "");
        }
      });
    };
    asyncInit();
    return (): void => {
      unmounted = true;
    };
  }, []);
  return (
    <Styled.Container>
      <OxPageHelmet title="Customer Dashboard" />
      <OxDashboardMenu menuItems={menuItems} variant="customer" />
      <Styled.DashboardContent>
        <Router>
          <OxCustomerDashboardMyAccount path="/" />
          <OxCustomerDashboardMyDetails path="/details/:patientId" />
          <OxCustomerDashboardMyJourney path="/my-journey" />
          <OxCustomerDashboardPastAppointments path="/past-appointments" />
          <OxCustomerDashboardUpcomingAppointments path="/upcoming-appointments" />
          <OxCustomerDashboardMyFiles path="/my-files" />
          <OxCustomerDashboardMyDoctor path="/my-doctor-clinic" />
          <OxCustomerDashboardMyTreatment path="/my-treatment-plan" />
          <OxCustomerDashboardCommunicationPreferences path="/communication-preferences" />
          <OxCustomerDashboardSavedPaymentDetails path="/saved-payment-details" />
          <Panel404 default />
        </Router>
      </Styled.DashboardContent>
    </Styled.Container>
  );
};
