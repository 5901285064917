import * as actions from "./authActions";

import { EAuthProcessStatus, TAuthState } from "../types";

import { createReducer } from "redux-act";

const INIT_STATE = {
  isLoggedIn: false,
  authProcessStatus: EAuthProcessStatus.Inaction,
  authError: [],
};

export const authReducer = createReducer<TAuthState>(
  {
    [actions.setLoggedIn]: (state) => ({ ...state, isLoggedIn: true }),
    [actions.setLoggedOut]: (state) => ({ ...state, isLoggedIn: false }),
    [actions.setAuthProcessStatus]: (state, payload) => {
      return {
        ...state,
        authProcessStatus: payload,
      };
    },
    [actions.setAuthError]: (state, payload) => ({
      ...state,
      authError: payload,
    }),
    [actions.clearAuthError]: (state) => ({ ...state, authError: [] }),
  },
  INIT_STATE
);
