import { EColors, EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { MultiSelect } from "react-multi-select-component";
import { OxIcon } from "../OxIcon";
import { TThemedInputContext } from "src/context/ThemedInputContext";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled.div`
  position: relative;
  z-index: 5;
`;

export const Multiselect = styled(MultiSelect)<{
  customTheme: TThemedInputContext;
  touched: boolean;
  invalid?: boolean;
}>(
  ({ customTheme, touched, invalid }) => css`
    -webkit-appearance: none;
    transition: 200ms;
    width: 100%;

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [10, 10, 10],
      [18, 18, 18]
    )}

    .rmsc,
    &.rmsc {
      --rmsc-main: ${customTheme.borderColor};
      --rmsc-hover: ${customTheme.hoverBackgroundColor};
      --rmsc-selected: ${customTheme.borderColor};
      --rmsc-border: ${customTheme.borderColor};
      --rmsc-gray: ${customTheme.color};
      --rmsc-bg: ${customTheme.backgroundColor};

      color: ${customTheme.color};

      ${fluidSizing([
        { prop: "--rmsc-h", values: [30, 30, 30] },
        { prop: "--rmsc-radius", values: [15, 15, 15] },
        { prop: "--rmsc-p", values: [15, 15, 15] },
      ])}

      &:focus {
        --rmsc-main: ${customTheme.hoverBorderColor};
        --rmsc-border: ${customTheme.hoverBorderColor};
        --rmsc-gray: ${customTheme.hoverColor};
      }

      ${invalid &&
      css`
        box-shadow: none;
      `}

      ${touched &&
      invalid &&
      css`
        --rmsc-border: ${EColors.DarkRed};
      `}

      .dropdown-container {
        &:focus-within {
          box-shadow: none;
        }
      }

      .dropdown-content {
        .panel-content {
          background: var(--rmsc-hover);
        }
      }

      .select-item {
        &.selected {
          background: ${customTheme.hoverBackgroundColor};
          color: ${customTheme.color};
        }
      }
    }
  `
);

export const Icon = styled(OxIcon)<{ customTheme: TThemedInputContext }>(
  ({ customTheme }) => css`
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(90deg) translateX(-50%);
    color: ${customTheme.color};
    ${fluidSizing([
      { prop: "height", values: [10, 10, 10] },
      { prop: "right", values: [15, 15, 15] },
    ])}
  `
);
