import * as Styled from "./OxStaffDashboardPatient.styled";

import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { OxStaffDashboardRoot } from "../OxStaffDashboardRoot";
import { OxStaffDashboardMyDetails } from "../OxStaffDashboardMyDetails";
import { OxStaffDashboardPatientExtras } from "../OxStaffDashboardPatientExtras";
import { OxStaffDashboardPatientInvoices } from "./components/OxStaffDashboardPatientInvoices";
import { OxSpinner } from "src/components/OxSpinner";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { getPatientProfile } from "src/services/api/patientProfile";
import { TPatientProfile, TTreatmentNote } from "src/services/api/api.types";
import { OxStaffDashboardPatientTreatmentNotes } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPatient/components/OxStaffDashboardPatientTreatmentNotes";
import { getTreatmentNotes } from "src/services/api/treatment";
import { useStore } from "react-redux";
import { ERoles, ERoutes } from "src/config/enums";
import { OxStaffDashboardPatientAppointments } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPatient/components/OxStaffDashboardPatientAppointments/OxStaffDashboardPatientAppointments";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxPageHelmet } from "src/components/OxPageHelmet";

type TLocationState = {
  patientId?: string;
  searchTerm?: string;
};

type TProps = {
  patientId?: string;
  location: { state: TLocationState };
};

export const OxStaffDashboardPatient: React.FC<RouteComponentProps<TProps>> = ({
  patientId,
  location,
}) => {
  const { validateResponse } = useValidateResponse();
  const { showAlert } = useContext(AlertContext);

  const [fetchError, setFetchError] = useState<boolean>(false);
  const [patientProfile, setPatientProfile] = useState<TPatientProfile>();
  const [treatmentNotes, setTreatmentNotes] = useState<TTreatmentNote[]>([]);
  const { patient } = patientProfile || ({} as TPatientProfile);
  const fullName = `${patient?.title ? `${patient?.title} ` : ``}${
    patient?.firstName ? `${patient?.firstName} ` : ``
  }${patient?.lastName}`;

  const store = useStore();
  const { user } = store.getState();
  const { roles } = user ?? { roles: [] };

  useEffect(() => {
    let unmounted = false;
    const asyncInit = async (): Promise<void> => {
      if (patientId || location?.state?.patientId) {
        const patient = await getPatientProfile({
          patientId: patientId ?? location?.state.patientId ?? "",
        });

        try {
          validateResponse(patient);
        } catch (e) {
          console.error(e.error.title);
          setFetchError(true);
          showAlert({
            type: EAlertVariant.Confirm,
            header: "Error",
            title: "Unable to fetch patient details",
            onlyConfirm: true,
            hideAfterConfirm: true,
            onConfirm: () => {
              navigate(ERoutes.PanelStaffDashboardPatientSearch, {
                state: {
                  searchTerm: location?.state?.searchTerm,
                },
              });
            },
          });
        }

        if (Array.isArray(patient) && patient.length === 0) {
          setFetchError(true);
          showAlert({
            type: EAlertVariant.Error,
            header: "Error",
            title: "Patient profile not found",
          });
        }

        if (!unmounted) {
          setPatientProfile(patient ?? undefined);
        }

        const existingTreatmentNotes = await getTreatmentNotes({
          patientId: patientId ?? location?.state.patientId ?? "",
        });

        try {
          validateResponse(existingTreatmentNotes);
          existingTreatmentNotes && setTreatmentNotes(existingTreatmentNotes);
        } catch (e) {
          console.error(e.title);
        }
      }
    };
    asyncInit();
    return (): void => {
      unmounted = true;
    };
  }, []);

  return (
    <OxStaffDashboardRoot location={location} noPadding={true}>
      <OxPageHelmet title="Patient Profile" />
      {patient && (
        <>
          <OxStaffDashboardMyDetails
            header="CONTACT DETAILS"
            id="details"
            image={patient?.photo?.path}
            fullName={fullName}
            phoneNumber={patient?.mobile}
            email={patient?.email}
            hasPatientAppointmentNotes={patient?.hasPatientAppointmentNotes}
          />
          <Styled.Container reducedWidth={true}>
            <OxStaffDashboardPatientExtras
              patientId={patient?.patientId ?? ""}
              patientName={fullName}
              patientProfile={patientProfile}
            />
          </Styled.Container>
          {!roles?.includes(ERoles.SkincareConsultant) && (
            <Styled.Container>
              <OxStaffDashboardPatientInvoices
                invoices={patientProfile?.invoices ?? []}
                patientId={patient?.patientId}
              />
            </Styled.Container>
          )}
          {!!patientProfile?.clinic.id &&
            !roles?.includes(ERoles.SkincareConsultant) && (
              <Styled.Container>
                <OxStaffDashboardPatientTreatmentNotes
                  treatmentNotes={treatmentNotes}
                  clinicId={patientProfile?.clinic.id}
                />
              </Styled.Container>
            )}
          <Styled.Container>
            <OxStaffDashboardPatientAppointments
              appointments={patientProfile?.appointments ?? []}
              patientId={patientId ?? ""}
            />
          </Styled.Container>
        </>
      )}
      {!patient && !fetchError && (
        <OxSpinner
          style={{
            marginTop: "15%",
            marginLeft: "40%",
          }}
        />
      )}
    </OxStaffDashboardRoot>
  );
};
