import * as Styled from "./OxEditAppointment.styled";

import React, { useContext, useEffect, useState } from "react";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { putEditAppointment } from "src/services/api/patient";
import {
  OxBookingStage1,
  TOxBookingStage1Data
} from "src/components/OxBooking/components/OxBookingStep5/components/OxBookingStage1";
import {
  OxBookingStage2,
  TOxBookingStage2Data
} from "src/components/OxBooking/components/OxBookingStep5/components/OxBookingStage2";
import {
  TAppointmentState,
  TOxStageProps
} from "src/components/OxBooking/components/OxBookingStep5";
import {
  EAnimatedWrapperAnimation,
  OxAnimatedWrapper
} from "src/components/OxAnimatedWrapper";
import { BookingFlowContext } from "src/context/BookingFlowContext";
import { OxBackButton } from "src/components/OxBackButton";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { useBreakpoints } from "src/hooks";
import { TAppointmentDate } from "src/services/api/api.types";
import { DateTime } from "luxon";
import { OnyxError } from "src/helpers/OnyxError";
import { OxSpinner } from "src/components/OxSpinner";
import { useValidateResponse } from "src/hooks/useValidateResponse";

type TProps = {
  appointmentId: string;
  clinicId: string;
  setAppointmentEditId: (id: string) => void;
  fetchAppointments: () => void;
};

export const OxEditAppointment: React.FC<TProps> = ({
  appointmentId,
  clinicId,
  setAppointmentEditId,
  fetchAppointments
}) => {
  const { validateResponse } = useValidateResponse();
  const { showAlert } = useContext(AlertContext);
  const bookingFlowContext = useContext(BookingFlowContext);
  const [appointmentDates, setAppointmentDates] = useState<TAppointmentDate[]>(
    []
  );
  const [appointmentsState, setAppointmentsState] = useState<
    TAppointmentState[]
  >([]);
  const [appointmentStateUpdated, setAppointmentStateUpdated] = useState<
    number
  >(0);
  const [currentStage, setCurrentStage] = useState(1);
  const [loading, setLoading] = useState<number>(0);
  const device = useBreakpoints();

  // We now pass consultation time through as parameter as it hasn't always been updated in the context otherwise
  const editAppointmentSubmit = async (
    consultationTime: DateTime
  ): Promise<void> => {
    try {
      const fullAppointment = bookingFlowContext.state.fullAppointment;
      if (!fullAppointment) throw Error("fullAppointment not defined");
      fullAppointment.startsAt = consultationTime?.toISO() as string;
      fullAppointment.endsAt = undefined;
      const result = await putEditAppointment({
        id: appointmentId,
        clinicId: clinicId,
        appointment: fullAppointment
      });

      try {
        validateResponse(result);
      } catch (e) {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR",
          title: "Appointment Edit Failed",
          message:
            "There was a problem editing your appointment. The selected time may no longer be available, if the problem persists please contact us."
        });
      }

      showAlert({
        type: EAlertVariant.Error,
        header: "Success",
        title: "You have successfully changed your appointment"
      });
      setAppointmentEditId("");
      await fetchAppointments();
    } catch (err) {
      showAlert({
        type: err.type,
        header: err.header,
        title: err.title,
        message: err.message
      });
    }
  };

  const handleNextButtonClick = (): void => {
    switch (currentStage) {
      case 1:
        if (bookingFlowContext.state.consultationDate) setCurrentStage(2);
        break;
      case 2:
        if (bookingFlowContext.state.consultationTime)
          editAppointmentSubmit(bookingFlowContext.state.consultationTime);
        break;
    }
  };

  const stepStages = [
    {
      index: 1,
      title: "Select Date",
      nextButtonTitle: "SELECT TIME",
      component: (props: TOxStageProps): JSX.Element => (
        <OxBookingStage1
          {...props}
          appointmentsState={appointmentsState}
          setAppointmentsState={setAppointmentsState}
          appointmentStateUpdated={appointmentStateUpdated}
          setAppointmentStateUpdated={setAppointmentStateUpdated}
        />
      ),
      onDataUpdate: (data: TOxBookingStage1Data): void => {
        if (data.appointmentDates) {
          setAppointmentDates(data.appointmentDates);
        }
        if (data.selectedDate) {
          bookingFlowContext.dispatch({
            type: EBookingFlowAction.SetConsultationDate,
            payload: data.selectedDate
          });
          setCurrentStage(2);
        }
      }
    },
    {
      index: 2,
      title: "Select Time",
      nextButtonTitle: "SUBMIT",
      component: (props: TOxStageProps): JSX.Element => (
        <OxBookingStage2 {...props} appointmentDates={appointmentDates} />
      ),
      onDataUpdate: (data: TOxBookingStage2Data): void => {
        if (data.selectedTime) {
          bookingFlowContext.dispatch({
            type: EBookingFlowAction.SetConsultationTime,
            payload: data.selectedTime
          });
          editAppointmentSubmit(data.selectedTime);
        }
      }
    }
  ];

  const handleBackButtonClick = (): void => {
    switch (currentStage) {
      case 2:
        setCurrentStage(currentStage - 1);
        break;
      default:
        setAppointmentEditId("");
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetConsultationDate,
          payload: undefined
        });
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetConsultationTime,
          payload: undefined
        });
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetConsultationLocationId,
          payload: undefined
        });
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetAppointmentTypeId,
          payload: undefined
        });
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetPractitioner,
          payload: undefined
        });
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetFullAppointmentForEdit,
          payload: undefined
        });
        break;
    }
  };

  const handleStageChange = (): void => {
    switch (currentStage) {
      case 1:
        bookingFlowContext.dispatch({
          type: EBookingFlowAction.SetConsultationTime,
          payload: undefined
        });
        break;
    }
  };

  useEffect(handleStageChange, [currentStage]);

  useEffect(() => {
    return (): void => {
      bookingFlowContext.reset();
    };
  }, []);

  return (
    <Styled.Container>
      <Styled.Header>
        <OxBackButton onClick={handleBackButtonClick} />
      </Styled.Header>
      <Styled.Footer>
        <OxThemedInput theme={EInputTheme.GoldTransparentAlternative}>
          <Styled.NextButton
            onClick={handleNextButtonClick}
            icon
            hide={!device.xs && currentStage > 2}
            disabled={!device.xs && currentStage > 2}
          >
            &nbsp;
            {["SELECT TIME", "CONFIRM"].map((item, index) => (
              <Styled.ButtonAnimation
                key={index}
                animation={EAnimatedWrapperAnimation.FadeIn}
                active={currentStage === index + 1}
              >
                <span>{item}</span>
              </Styled.ButtonAnimation>
            ))}
          </Styled.NextButton>
        </OxThemedInput>
      </Styled.Footer>
      <Styled.Content>
        {loading > 0 && (
          <OxSpinner
            addTranslate
            style={{
              left: "50%",
              top: "50%",
              position: "absolute",
              opacity: 0.8,
              zIndex: 10
            }}
          />
        )}
        {stepStages.map(stage => {
          return (
            <OxAnimatedWrapper
              key={stage.index}
              animation={EAnimatedWrapperAnimation.FadeIn}
              // relativeBox
              active={currentStage === stage.index}
            >
              {stage.component({
                stepIsActive: currentStage === stage.index,
                currentStage,
                stageIndex: stage.index,
                onDataUpdate: stage.onDataUpdate,
                setLoading: setLoading
              })}
            </OxAnimatedWrapper>
          );
        })}
      </Styled.Content>
    </Styled.Container>
  );
};
