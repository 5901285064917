import React, { useContext, useState } from "react";
import { TAppointmentDate, TPractitioner } from "src/services/api/api.types";
import styled, { css } from "styled-components";

import { BookingFlowContext } from "src/context/BookingFlowContext";
import { DateTime } from "luxon";
import { OxBookingStage } from "../OxBookingStage";
import { OxTimeSelect, TAvailableTime } from "src/components/OxTimeSelect";
import { TOxStageProps } from "../../OxBookingStep5";
import { clinikoGetPractitionerByAppointmentAndTime } from "src/services/api";
import { toVw } from "src/utils";
import { fireEvent } from "src/helpers/TagManager";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";

export type TOxBookingStage2Data = {
  selectedTime: DateTime;
  doctor: TPractitioner;
};

export type TOxBookingStage2Props = TOxStageProps & {
  appointmentDates: TAppointmentDate[];
};

export const StyledTimeSelect = styled(OxTimeSelect)(
  ({ theme }) => css`
    ${theme.breakpoints.only("xs")} {
      padding-bottom: ${toVw(20, "xs")};
    }
  `
);

export const OxBookingStage2 = ({
  stepIsActive,
  stageIndex,
  currentStage,
  appointmentDates,
  onDataUpdate,
  setLoading
}: TOxBookingStage2Props): JSX.Element => {
  const { state } = useContext(BookingFlowContext);

  const websiteDataContext = useContext(WebsiteDataContext);
  const timezone =
    websiteDataContext.locations.find(
      location => location.id === state.consultationLocationId
    )?.timezone ?? "Europe/London";

  const [availableHours, setAvailableHours] = useState<TAvailableTime[]>([]);

  const updateAvailableHours = (): void => {
    const times = appointmentDates
      .filter(item => {
        const itemDatetime = DateTime.fromISO(item.startsAt).setZone(timezone);
        return (
          state.consultationDate?.hasSame(itemDatetime, "year") &&
          state.consultationDate?.hasSame(itemDatetime, "month") &&
          state.consultationDate?.hasSame(itemDatetime, "day")
        );
      })
      .map(item => ({
        startsAt: DateTime.fromISO(item.startsAt).setZone(timezone),
        available: item.available
      }));

    setAvailableHours(times);
  };

  const handleTimeChange = async (time: DateTime): Promise<void> => {
    const data: { [key: string]: DateTime | TPractitioner } = {
      selectedTime: time
    };
    if (!state.practitioner) {
      setLoading(prev => prev + 1);
      const doctor = await clinikoGetPractitionerByAppointmentAndTime({
        clinicId: state.consultationLocationId as string,
        appointmentTypeId: state.appointmentTypeId as string,
        startsAt: time.toUTC().toString()
      });
      setLoading(prev => prev - 1);
      data.doctor = doctor as TPractitioner;
    }
    fireEvent({ event: "TimeClickedInTimeSelect" });
    onDataUpdate(data);
  };

  const onStageStart = (): void => {
    updateAvailableHours();
  };

  return (
    <OxBookingStage
      stepIsActive={stepIsActive}
      currentStage={currentStage}
      stageIndex={stageIndex}
      onStageStart={onStageStart}
    >
      <StyledTimeSelect
        availableHours={availableHours}
        selectedTime={state.consultationTime}
        onTimeSelect={handleTimeChange}
      />
    </OxBookingStage>
  );
};
