import * as Styled from "./OxStaffDashboardPsychologicalAttributesHeader.styled";

import React from "react";
import { OxIcon } from "src/components/OxIcon";
import { TArchetype, TRespondent } from "src/services/api/api.types";

type TProps = {
  respondent?: TRespondent;
  archetype?: TArchetype;
};

export const OxStaffDashboardPsychologicalAttributesHeader = ({
  respondent: { firstname, lastname },
  archetype
}: TProps): JSX.Element => {
  return (
    <Styled.Container>
      <Styled.Logo to="/">
        <OxIcon name="ouronyx" />
      </Styled.Logo>
      <Styled.Details>
        <Styled.Header>Psychological Attributes</Styled.Header>
        <Styled.PatientProfile>
          <Styled.Type archetype={archetype}></Styled.Type>
          {` | ${firstname} ${lastname}`}
        </Styled.PatientProfile>
      </Styled.Details>
      <Styled.Border />
    </Styled.Container>
  );
};
