import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled.div`
  ${fluidSizing([
    { prop: "padding-left ", values: [25, 50, 60] },
    { prop: "padding-right ", values: [25, 50, 60] }
  ])}
`;

export const Invoices = styled.div``;
export const Title = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [12, 12, 12],
    [18, 18, 18],
    true
  )}
`;

export const Table = styled.table`
  width: 100%;
`;

export const THeadCell = styled.th<{ align?: "left" | "right" }>(
  ({ align }) => css`
    text-align: ${align ?? "center"};

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [10, 10, 10],
      [18, 18, 18],
      true
    )}
  `
);

export const TCell = styled.td<{ align?: string }>(
  ({ align }) => css`
    text-align: ${align ?? "center"};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [8, 8, 8],
      [18, 18, 18],
      true
    )}
  `
);

export const NoInvoices = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [10, 10, 10],
    [18, 18, 18],
    true
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
`;

export const PrintMessage = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [9, 9, 9],
    [16, 16, 16],
    true
  )}
`;
