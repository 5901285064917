import DotObject from "dot-object";

type LOOSE_OBJECT = { [key: string]: any };

/**
 * Convert FormData object into regular object
 * supports nested/multidimensional arrays so long as they are . separated
 * @param formData
 */
export const formDataToObject = (formData: FormData): LOOSE_OBJECT => {
  const object: LOOSE_OBJECT = {};
  formData.forEach(function(value, key) {
    DotObject.set(key, value, object);
  });

  // Filter out unset/undefined items
  Object.keys(object).forEach(key => {
    if (Array.isArray(object[key])) {
      object[key] = [...object[key]].filter(Boolean);
    }
  });

  return object;
};
