import {
  TError,
  TMakeup,
  TPatientSearchResult,
  TRefreshments
} from "src/services/api/api.types";
import {
  apiGetCall,
  apiPatchCall,
  apiPostCall
} from "src/services/api/api.core";
import { EApiEndpoint } from "src/config/enums";
import { applyValues } from "src/utils/applyValues";

export const getSearchPatients = async (
  values: {
    clinicId: string;
  },
  params?: {
    patientId?: string;
    name?: string;
  }
): Promise<TPatientSearchResult[] | null> => {
  return await apiGetCall(applyValues(EApiEndpoint.ReceptionPatient, values), {
    params: {
      id: params?.patientId,
      name: params?.name
    },
    credentials: true
  });
};

export const getMakeup = async (values: {
  patientId: string;
}): Promise<TMakeup | null> => {
  return await apiGetCall(applyValues(EApiEndpoint.ReceptionMakeup, values), {
    credentials: true
  });
};

export const postMakeup = async (
  values: {
    patientId: string;
  },
  body: {
    makeup: string;
  }
): Promise<null> => {
  return await apiPostCall(
    applyValues(EApiEndpoint.ReceptionMakeup, values),
    JSON.stringify(body),
    {
      credentials: true
    }
  );
};

export const postReferral = async (
  values: {
    patientId: string;
    clinicId: number;
  },
  body: {
    source: string;
  }
): Promise<null> => {
  return await apiPostCall(
    applyValues(EApiEndpoint.ReceptionReferral, values),
    JSON.stringify(body),
    {
      credentials: true
    }
  );
};

export const getRefreshments = async (values: {
  patientId: string;
}): Promise<TRefreshments | null> => {
  return await apiGetCall(
    applyValues(EApiEndpoint.ReceptionRefreshments, values),
    {
      credentials: true
    }
  );
};

export const postRefreshments = async (
  values: {
    patientId: string;
  },
  body: {
    refreshments: string;
    notes: string;
  }
): Promise<(TRefreshments & TError) | null> => {
  return await apiPostCall(
    applyValues(EApiEndpoint.ReceptionRefreshments, values),
    JSON.stringify(body),
    {
      credentials: true
    }
  );
};

export const patchArrival = async (
  values: { clinicId: string; appointmentId: string },
  body: { status: "arrived" | "did_not_arrive" | undefined }
): Promise<null> => {
  return await apiPatchCall(
    applyValues(EApiEndpoint.ReceptionAppointmentAttendance, values),
    JSON.stringify(body),
    {
      credentials: true
    }
  );
};
