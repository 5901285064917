import { RouteComponentProps, WindowLocation } from "@reach/router";
import React from "react";
import { OxPrivateRoute } from "src/panel/components/OxPrivateRoute";
import { OxStaffPin } from "src/panel/components/OxStaffPin";

type TStateProps = {
  description?: string;
  successUrl: string;
  successNavigateOptions: Record<string, unknown>;
};

export interface TPanelStaffPinProps extends RouteComponentProps {
  location: WindowLocation<TStateProps>;
}

export const PanelStaffPin = ({
  location,
}: RouteComponentProps): JSX.Element => {
  return (
    <OxPrivateRoute
      component={OxStaffPin}
      location={location}
      description={
        location?.state?.description ??
        "Thank you for filling in this form, please hand the device back to the doctor."
      }
      successUrl={location?.state?.successUrl}
      successNavigateOptions={location?.state?.successNavigateOptions}
    />
  );
};
