import * as Styled from "./OxPanelStaffLanding.styled";

import { useDispatch, useStore } from "react-redux";

import { ERoles, ERoutes } from "src/config/enums";
import { OxDashboardElement } from "src/panel/components/OxPanelStaffLanding/components";
import { OxIcon } from "src/components/OxIcon";
import React, { useContext } from "react";
import { logoutFlow } from "src/panel/store/auth/authActions";
import { navigate } from "@reach/router";
import { BookingFlowContext } from "src/context/BookingFlowContext";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxPanelStaffLanding = (): JSX.Element => {
  const bookingFlowContext = useContext(BookingFlowContext);
  const store = useStore();
  const state = store.getState();
  const dispatch = useDispatch();

  const { roles, lastname, firstname, title } = state.user ?? {
    roles: [],
    lastname: "",
    firstname: "",
    title: ""
  };
  const getNoun = (): string => {
    switch (true) {
      case roles.includes(ERoles.Practitioner):
        return "Doctor";
      case roles.includes(ERoles.Nurse):
        return "Nurse";
      case roles.includes(ERoles.Barista):
        return "Host";
      case roles.includes(ERoles.SkincareConsultant):
        return "Skincare Therapist";
      case roles.includes(ERoles.MakeupArtist):
        return "Makeup Artist";
      case roles.includes(ERoles.Receptionist):
        return "Advisor";
      default:
        return "";
    }
  };

  const data = [
    {
      title: "Client Onboarding",
      description:
        "The induction workflow for clients arriving at our clinics.",
      to: ERoutes.PanelWelcomeInternal,
      show: roles.includes(ERoles.Receptionist),
    },
    {
      title: "Refreshments",
      description:
        "A listing of today's refreshment requests with live updates.",
      to: ERoutes.PanelBaristaRefreshmentsList,
      show: roles.includes(ERoles.Barista),
    },
    {
      title: `${getNoun()} Dashboard`,
      description: `An overview for Ouronyx ${getNoun().toLowerCase()}s.`,
      to: ERoutes.PanelStaffDashboard,
      show:
        roles.includes(ERoles.Practitioner) ||
        roles.includes(ERoles.Barista) ||
        roles.includes(ERoles.MakeupArtist) ||
        roles.includes(ERoles.Receptionist) ||
        roles.includes(ERoles.Nurse),
    },
  ];

  const onLogoutClick = (): void => {
    dispatch(logoutFlow(bookingFlowContext));
    navigate(ERoutes.PanelStaffLogin);
  };

  return (
    <Styled.Container>
      <OxPageHelmet title="Staff Landing" />
      <Styled.Header>
        <Styled.Logout>
          <OxIcon name="arrow-2" />
          <Styled.Label onClick={onLogoutClick}>LOGOUT</Styled.Label>
        </Styled.Logout>
        <Styled.WelcomeText>
          Welcome {title} {firstname} {lastname}
        </Styled.WelcomeText>
      </Styled.Header>
      <Styled.ContentContainer
        count={data.filter((element) => element.show).length}
      >
        {data.map(
          (element, index) =>
            element.show && <OxDashboardElement {...element} key={index} />
        )}
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
