import styled, { css } from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";

export const UpcomingConsultation = styled.div(
  ({ theme }) => css`
${createTextStyle(
  EFonts.Montserrat,
  EFontWeight.SemiBold,
  [12, 12, 12],
  [20, 20, 20]
)}
  color: ${theme.colors.primary.main};
  ${fluidSizing([
    { prop: "margin-top", values: [15, 13, 13] },
    { prop: "margin-bottom", values: [10, 10, 10] }
  ])}
`
);

export const ConsultationHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.basic.black};
    color: ${theme.colors.primary.main};
    button {
      text-transform: uppercase;
    }
    ${fluidSizing([
      { prop: "padding-bottom", values: [16, 16, 16] },
      { prop: "margin-bottom", values: [33, 33, 33] }
    ])}
  `
);

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Location = styled.button<{ active: boolean }>(
  ({ active }) => css`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [12, 12, 12],
    [20, 20, 20]
  )}
  ${active &&
    css`
      font-weight: ${EFontWeight.SemiBold};
      pointer-events: none;
    `}
    ${fluidSizing([{ prop: "margin-right", values: [12, 12, 12] }])}
  `
);

export const EmptyDay = styled.div`
  text-align: center;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [10, 10, 10],
    [20, 20, 20]
  )}
`;

export const ViewAll = styled.button`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [10, 10, 10],
    [20, 20, 20]
  )}
`;

export const OxPatientsSliderContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    ${theme.breakpoints.up("s")} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${theme.breakpoints.up("m")} {
      grid-template-columns: repeat(4, 1fr);
    }
    ${fluidSizing([{ prop: "gap", values: [5, 5, 5] }])}
  `
);
