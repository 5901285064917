import { ERoutes } from "src/config/enums";
import { OxCreateAccount } from "src/panel/components/OxCreateAccount";
import { OxLoggedInRedirect } from "src/components/OxLoggedInRedirect";
import React from "react";
import { RouteComponentProps } from "@reach/router";

export const PanelCreateAccount = (props: RouteComponentProps): JSX.Element => {
  return (
    <OxLoggedInRedirect
      component={OxCreateAccount}
      to={ERoutes.PanelCustomerDashboard}
    />
  );
};
