import styled, { css } from "styled-components";

import { fluidSizing } from "src/utils";

import * as StyledStep5 from "src/components/OxBooking/components/OxBookingStep5/OxBookingStep5.styled";

export const Container = styled(StyledStep5.Container)(
  ({ theme }) => css`
    grid-gap: 2rem;

    ${theme.breakpoints.only("xs")} {
      grid-template:
        "StepHeader"
        "StepContent"
        "StepFooter";
    }
    ${theme.breakpoints.only("s")} {
      grid-template:
        "StepHeader StepHeader"
        "StepContent StepContent"
        "blank StepFooter";
    }
    ${theme.breakpoints.up("m")} {
      grid-template:
        "StepHeader StepHeader"
        "StepContent StepContent"
        "blank StepFooter";
    }
  `
);

export const Header = styled(StyledStep5.Header)(({ theme }) => css``);
export const Footer = styled(StyledStep5.Footer)(({ theme }) => css``);
export const Content = styled(StyledStep5.Content)(
  ({ theme }) => css`
    ${fluidSizing([{ prop: "height", values: [300, 300, 300] }])}
  `
);

export const NextButton = styled(StyledStep5.NextButton)(({ theme }) => css``);
export const ButtonAnimation = styled(StyledStep5.ButtonAnimation)(
  ({ theme }) => css``
);
