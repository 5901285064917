import * as Styled from "./OxDashboardContentWrapper.styled";

import React, { useEffect, useState } from "react";

import { ERoutes } from "src/config/enums";
import { OxIcon } from "src/components/OxIcon";
import { OxSpinner } from "src/components/OxSpinner";

type TProps = {
  title?: string;
  onInit?: () => Promise<void>;
  noReturn?: boolean;
  noLoadingDelay?: boolean;
};

export const OxDashboardContentWrapper = ({
  children,
  onInit,
  noLoadingDelay,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  const [loading, setLoading] = useState(!!onInit);

  useEffect(() => {
    let unmounted = false;
    const asyncInit = async (): Promise<void> => {
      if (onInit) {
        await onInit();
        setTimeout(
          () => {
            if (!unmounted) {
              setLoading(false);
            }
          },
          noLoadingDelay ? 0 : 500
        );
      } else {
        if (!unmounted) {
          setLoading(false);
        }
      }
    };
    asyncInit();
    return (): void => {
      unmounted = true;
    };
  }, []);

  return (
    <Styled.Container {...props}>
      {!props.noReturn && (
        <Styled.Return to={ERoutes.PanelCustomerDashboard}>
          <OxIcon name="arrow-2" />
          <span>Return</span>
        </Styled.Return>
      )}
      {props.title && <Styled.Header>{props.title}</Styled.Header>}
      {loading ? <OxSpinner /> : <Styled.Content>{children}</Styled.Content>}
    </Styled.Container>
  );
};
