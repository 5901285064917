import * as Styled from "./OxDashboardElement.styled";

import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";

import { OxLink } from "src/components/OxLink";
import React from "react";

type TProps = {
  title: string;
  description: string;
  to?: string;
};

export const OxDashboardElement = (props: TProps): JSX.Element => {
  return (
    <Styled.Container>
      <Styled.Title>{props.title}</Styled.Title>
      <Styled.Description>{props.description}</Styled.Description>
      <OxThemedInput theme={EInputTheme.BackgroundGold}>
        <OxLink to={props.to}>
          <Styled.ViewButton icon>VIEW</Styled.ViewButton>
        </OxLink>
      </OxThemedInput>
    </Styled.Container>
  );
};
