import styled, {css} from "styled-components";
import {OxContainer} from "src/components/OxContainer";
import {fluidSizing} from "src/utils";
import {createTextStyle} from "src/utils/createTextStyle";
import {EFonts, EFontWeight} from "src/config/enums";

export const Container = styled(OxContainer)`
  ${fluidSizing([{ prop: "padding-bottom", values: [72, 72, 72]}])}
`;

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    button {
      color: ${theme.colors.primary.main};
    }
    button:nth-of-type(2) {
      opacity: 0;
      visibility: none;
      cursor: default;
      height: 1px;
    }
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      ${fluidSizing([
    { prop: "margin-left", values: [20, null, null] },
    { prop: "margin-right", values: [20, null, null] },
    { prop: "margin-bottom", values: [40, 40, 40]}
  ])}
    }
    ${fluidSizing([{ prop: "margin-bottom", values: [40, 40, 40]}])}
  `
);

export const HeaderName = styled.h2(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.basic.black};
    ${theme.breakpoints.only("xs")} {
      text-align: center;
    }
    ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [18, 27, 27],
    [34, 34, 34]
  )}
    ${fluidSizing([
    { prop: "padding-top", values: [5, 0, 0] },
    { prop: "padding-bottom", values: [5, 16, 16] },
    { prop: "padding-left", values: [10, 30, 30] },
    { prop: "padding-right", values: [10, 30, 30] }
  ])}
  `
);
