import * as Styled from "./OxCustomerDashboardMyDoctor.styled";

import React, { useState } from "react";
import { TClinic, TPractitioner } from "src/services/api/api.types";
import {
  getPatientClinic,
  getPatientPractitioner,
} from "src/services/api/patient";

import { RouteComponentProps } from "@reach/router";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxCustomerDashboardMyDoctor: React.FC<
  RouteComponentProps
> = () => {
  const [details, setDetails] = useState<{
    practitioner: TPractitioner;
    clinic: TClinic;
  }>();

  const fetchData = async (): Promise<void> => {
    const practitionerData = await getPatientPractitioner();
    const clinicData = await getPatientClinic();
    if (practitionerData && clinicData)
      setDetails({
        practitioner: practitionerData,
        clinic: clinicData,
      });
  };

  return (
    <Styled.Container title="My Doctor / Clinic" onInit={fetchData}>
      <OxPageHelmet title="My Doctor / Clinic" />
      <Styled.Header>
        <p>
          Your Doctor is: <span>{details?.practitioner?.label}</span>
        </p>
        <p>
          Your Clinic is: <span>{details?.clinic?.businessName}</span>
        </p>
      </Styled.Header>
      {/* <Styled.Paragraph>Lorem ipsum dolor sit amet</Styled.Paragraph> */}
    </Styled.Container>
  );
};
