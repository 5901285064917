import styled, {css} from "styled-components";
import {fluidSizing} from "src/utils";
import {createTextStyle} from "src/utils/createTextStyle";
import {EFonts, EFontWeight} from "src/config/enums";

export const ContentContainer = styled.div(
  ({theme}) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.basic.lightPanel};
  align-items: center;
  ${fluidSizing([
    { prop: "margin-top", values: [60, 60, 60] },
    { prop: "margin-bottom", values: [60, 60, 60] },
    { prop: "padding-top", values: [27, 27, 27] },
    { prop: "padding-bottom", values: [57, 57, 57] },
    { prop: "padding-left", values: [53, 53, 53] },
    { prop: "padding-right", values: [53, 53, 53] },
])}
`);

export const Title = styled.h2`
  text-align: center;
  letter-spacing: -0.8px;
  ${createTextStyle(
  EFonts.Hatton,
  EFontWeight.Normal,
  [36, 36, 36],
  [34, 34, 34]
)}
${fluidSizing([{ prop: "margin-bottom", values: [31, 31, 31] }])}
`;

export const Info = styled.div`
${createTextStyle(
  EFonts.Montserrat,
  EFontWeight.Light,
  [12, 12, 12],
  [19, 19, 19]
)}
`;

export const Inputs = styled.div`
 ${fluidSizing([{ prop: "width", values: [387, 387, 387] }])}
  > * {
    width: 100%;
    ${fluidSizing([{ prop: "margin-top", values: [16, 16, 16] }])}
  }
`;
