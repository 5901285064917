import React, { useContext, useState } from "react";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import * as Styled from "./OxPatientArrivalStatus.styled";
import { TAppointment } from "src/services/api/api.types";
import { patchArrival } from "src/services/api/reception";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { EOxInputType, OxInput } from "src/components/OxInput";
import { useValidateResponse } from "src/hooks/useValidateResponse";

enum EArrivalOptions {
  Unsubmitted = "unsubmitted",
  Arrived = "arrived",
  NotArrived = "did_not_arrive"
}

type TSelect = {
  label: string;
  value: EArrivalOptions;
};

type TProps = {
  consultation: TAppointment;
};

export const OxPatientArrivalStatus = ({ consultation }: TProps) => {
  const { validateResponse } = useValidateResponse();
  const { showAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);

  const arrivalValues: TSelect[] = [
    {
      label: "Select Arrival",
      value: EArrivalOptions.Unsubmitted
    },
    {
      label: "Arrived",
      value: EArrivalOptions.Arrived
    },
    {
      label: "Not Arrived",
      value: EArrivalOptions.NotArrived
    }
  ];

  /**
   * Won't actually ever return unknown but stops linter complaining
   */
  const getArrivalSelectInitialValue = (): TSelect | undefined => {
    let arrivalStatus;
    switch (true) {
      case consultation.patientArrived:
        arrivalStatus = arrivalValues.find(
          val => val.value === EArrivalOptions.Arrived
        );
        break;
      case consultation.didNotArrive:
        arrivalStatus = arrivalValues.find(
          val => val.value === EArrivalOptions.NotArrived
        );
        break;
    }

    return arrivalStatus ?? arrivalValues[0];
  };

  const [arrival, setArrival] = useState<TSelect | undefined>(
    getArrivalSelectInitialValue
  );

  const submitArrival = (value: TSelect): void => {
    if (value?.value !== arrival?.value) {
      setLoading(true);
      patchArrival(
        {
          clinicId: consultation.clinicId ?? "",
          appointmentId: consultation.id
        },
        {
          status:
            value?.value === EArrivalOptions.Unsubmitted
              ? undefined
              : value?.value
        }
      )
        .then(response => validateResponse(response))
        .then(() => setArrival(value))
        .catch(e => {
          showAlert({
            type: EAlertVariant.Error,
            header: "Error",
            title: "Unable to update arrival status",
            message: e.message ?? e.title
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <OxThemedInput theme={EInputTheme.BackgroundWhite}>
      <Styled.Select
        disableFireOnValueChangeUntilTouched
        labelName="label"
        valueName="value"
        items={arrivalValues}
        value={arrival}
        onValueChange={submitArrival}
        loading={loading}
      />
      {arrival?.value === EArrivalOptions.NotArrived && (
        <OxInput
          type={EOxInputType.Hidden}
          name={`not_arrived`}
          value={consultation.id}
        />
      )}
    </OxThemedInput>
  );
};
