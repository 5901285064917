import * as StyledCommon from "../../OxStaffDashboardPsychologicalAttributes.styled";

import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { transparentize } from "polished";

const height = 25;
const width = 20;
const shapeSpacing = 5;

export const ItemContainer = styled(StyledCommon.ItemContainer)`
  justify-content: flex-start;
  margin-right: 0 !important;
  &::after {
    display: none;
  }
`;

export const NumberInner = styled(StyledCommon.NumberInner)<{ total: number }>(
  ({ total }) => css`
    flex: 0;
    margin-right: auto;
    text-align: left;
    transform: translateX(-50%);

    ${fluidSizing([
      {
        prop: "margin-left",
        values: [width, width, width],
        formatter: (val): string => `${((total - 1) * val) / 2 + val / 2}vw`
      }
    ])}
  `
);

export const Header = styled(StyledCommon.Header)<{ total: number }>(
  ({ total }) => css`
    flex: 0;
    margin-right: auto;
    text-align: left;
    transform: translateX(-50%);

    ${fluidSizing([
      {
        prop: "margin-left",
        values: [width, width, width],
        formatter: (val): string => `${((total - 1) * val) / 2 + val / 2}vw`
      }
    ])}
  `
);

export const Fears = styled.div<{ total: number }>(
  ({ theme, total }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    &::after {
      // As it's super tricky to line this diagonal line up,
      // We're adding a left border to cover up any overlap
      // from the underlines
      background: ${theme.colors.basic.wildSand};
      border-left: 0.5rem solid ${theme.colors.basic.wildSand};
      border-right: 0.1rem solid ${theme.colors.basic.black};
      // To make up for it being border rather than background
      // So over laps with bottom underscore
      bottom: -0.1rem;
      content: "";
      display: block;
      height: 150%;
      position: absolute;
      transform: rotate(-${Math.atan(width / height) / 2}rad);
      transform-origin: bottom left;
      width: 0;
      z-index: 10;

      // Calculation:
      // width of largest trapezoid (total * val)
      // + 5vw (for offset of text elements)
      // - 4rem (offset of underscore element)
      // - 0.5rem (hacky white border to sort out underscore overflow)

      ${fluidSizing([
        {
          prop: "left",
          values: [width, width, width],
          formatter: (val): string => `calc(${total * val + 5}vw - 4.5rem)`
        }
      ])}
    }
  `
);

export const Fear = styled.div<{ index: number; total: number }>(
  ({ theme, index, total }) => css`
    position: relative;
    z-index: 5;

    ${fluidSizing([
      {
        prop: "width",
        values: [width, width, width],
        formatter: (val): string =>
          `${((total - index) * val) / 2 + (index * val) / 2}vw`
      }
    ])}

    &::before {
      content: "";
      display: block;
      position: relative;
    }

    &::before {
      background: ${transparentize(
        ((index - 1) / total) * 0.7,
        theme.colors.primary.main
      )};
      ${index !== total &&
        css`
          border-bottom: 0.5rem solid ${theme.colors.basic.white};
        `}
      height: 100%;
      left: 100%;
      //ScaleZ removes weird border caused by clip-path in chrome
      transform: translateX(-50%) scaleZ(1);

      ${fluidSizing([
        {
          prop: "width",
          values: [width, width, width],
          formatter: (val): string => `${val * index}vw`
        },
        {
          prop: "height",
          values: [height, height, height]
        }
      ])}
      ${index === 1 &&
        css`
          clip-path: polygon(50% 0, 100% 100%, 0% 100%);
        `}
      ${index >= 2 &&
        fluidSizing([
          {
            prop: "clip-path",
            values: [width, width, width],
            formatter: (val): string =>
              `polygon(${val / 2}vw 0, ${val * index -
                val / 2}vw 0, 100% 100%, 0 100%)`
          }
        ])};
    }
  `
);

export const FearText = styled.span<{ index: number; total: number }>(
  ({ theme, index, total }) => css`
    bottom: 0;
    overflow: visible;
    position: absolute;
    width: 100%;
    white-space: nowrap;

    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Normal,
      [10, 10, 10],
      [10, 10, 10]
    )}

    ${fluidSizing([
      {
        prop: "left",
        values: [width, width, width],
        formatter: (val): string => `calc(100% + ${(val / 2) * index + 5}vw)`
      }
    ])}

    ${index !== total &&
      fluidSizing([
        {
          prop: "margin-bottom",
          values: [shapeSpacing, shapeSpacing, shapeSpacing]
        }
      ])}

    &::before,
    &::after {
      background-color: ${theme.colors.basic.black};
      content: "";
      display: block;
      position: absolute;
    }

    &::before {
      bottom: 0;
      content: none;
      left: -4rem;
      transform-origin: bottom right;
      width: 1px;

      //Quick maffs - basic trigonometry
      ${fluidSizing([
        {
          prop: "transform",
          values: [width, width, width],
          formatter: (val): string =>
            `rotate(-${Math.atan(val / ((val * height) / width)) / 2}rad)`
        },
        {
          prop: "height",
          values: [width, width, width],
          formatter: (val): string =>
            `${Math.pow(
              Math.pow(val, 2) + Math.pow((val * height) / width, 2),
              0.5
            )}vw`
        }
      ])}
    }

    &::after {
      bottom: 0;
      height: 1px;
      left: -4rem;
      width: 3rem;
    }
  `
);
