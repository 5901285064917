import * as Styled from "./OxCustomerDashboardAppointments.styled";

import React, { useContext, useEffect, useState } from "react";
import {TAppointment, TClinic} from "src/services/api/api.types";
import {
  getPatientAppointments,
  patchCancelAppointment,
} from "src/services/api/patient";

import { DateTime } from "luxon";
import { EAlertVariant, AlertContext } from "src/context/AlertContext";
import { OxEditAppointment } from "./components/OxEditAppointment";
import { BookingFlowContext } from "src/context/BookingFlowContext";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import {clinikoGetAppointmentTypes, getClinicById} from "src/services/api";
import { graphql, useStaticQuery } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";

type TAppointmentItem = {
  id: string;
  type: string;
  date: DateTime;
  clinic: string;
  clinicId: string;
  practitionerName: string;
  fullAppointment: TAppointment;
};

export enum AppointmentListType {
  BOTH = "both",
  PAST = "past",
  UPCOMING = "upcoming",
}

type TProps = {
  type: AppointmentListType;
  title: string;
};

export const OxCustomerDashboardAppointments = ({
  type,
  title,
}: SCProps<"div", TProps>): JSX.Element => {
  const { validateResponse } = useValidateResponse();
  const { getConfigValue } = useContext(WebsiteDataContext);
  const bookingFlowContext = useContext(BookingFlowContext);
  const [appointments, setAppointments] = useState<TAppointment[]>([]);
  const [clinic, setClinic] = useState<TClinic>();
  const { showAlert } = useContext(AlertContext);
  const [appointmentEditId, setAppointmentEditId] = useState("");
  const [appointmentClinicId, setAppointmentClinicId] = useState("");

  const data = useStaticQuery(
    graphql`
      query {
        siteSettings: allSanitySiteSettings {
          nodes {
            myAccount_upcomingAppointmentsCopy
          }
        }
      }
    `
  );

  const getClinicData = async (): Promise<void> => {
    const fetchData = await getClinicById({clinicId: ''});

    if (fetchData) {
      const clinincs = fetchData.reduce((total, current) => {
        total[current.id] = current;
        return total;
      }, {});
      setClinic(clinincs);
    }
  };

  const appointmentsData: TAppointmentItem[] = appointments.map((item) => {
    const data =  {
      id: item.id || "",
        type: item.appointmentType || "",
      date: DateTime.fromISO(item.startsAt || "").setZone(
      clinic?.timezone ?? "Europe/London"
    ),
      clinic: clinic?.businessName || "",
      clinicId: item?.clinicId || "",
      practitionerName: item.practitionerName || "",
      fullAppointment: item,
    }
    return data;
  });

  const fetchAppointments = async (): Promise<void> => {
    const data = await getPatientAppointments();
    if (Array.isArray(data)) {
      setAppointments(data);
    }
  };

  useEffect(() => {
    let unmounted = false;

    getClinicData()

    return () => {
      unmounted = true;
    };
  }, []);

  const cancelAppointment = async (clinicId: string, id: string): Promise<void> => {
    try {
      const result = await patchCancelAppointment({ clinicId: clinicId, id: id });
      validateResponse(result);
      showAlert({
        type: EAlertVariant.Error,
        header: "Success",
        title: "You have successfully cancelled your appointment",
      });
      await fetchAppointments();
    } catch (err) {
      showAlert({
        type: EAlertVariant.Error,
        header: "ERROR ALERT: OCDA1",
        title: "Sorry, something went wrong",
        message:
          "There was a problem cancelling your appointment. Please check your details and try again, if the problem persists please contact us.",
      });
    }
  };

  const upcomingAppointments = appointmentsData.filter(
    (item) => item.date.diffNow().milliseconds > 0
  );

  const pastAppointments = appointmentsData.filter(
    (item) => item.date.diffNow().milliseconds <= 0
  );

  const getAndSetAppointmentTypes = async (clinicId: string | number) => {
    const appointmentTypes = await clinikoGetAppointmentTypes(
      clinicId as string
    );

    bookingFlowContext.dispatch({
      type: EBookingFlowAction.SetAppointmentTypes,
      payload: Array.isArray(appointmentTypes) ? appointmentTypes || [] : [],
    });
  };

  const renderItem = ({
    item,
    isPast,
    inGracePeriod,
  }: {
    item: TAppointmentItem;
    isPast?: boolean;
    inGracePeriod?: boolean;
  }): JSX.Element => (
    <>
      <Styled.Details>
        <Styled.DetailHeader>
          {item.date.setLocale("en").toLocaleString(DateTime.DATE_FULL) +
            " - " +
            item.date.setLocale("en").toLocaleString(DateTime.TIME_SIMPLE)}
          {!isPast && item.id && inGracePeriod && (
            <Styled.DetailEdit>
              <Styled.Link
                onClick={(): void => {
                  if (inGracePeriod) {
                    showAlert({
                      type: EAlertVariant.Confirm,
                      header: "CONFIRMATION",
                      title: "Appointment Cancellation",
                      message:
                        "Are you sure you want to cancel this appointment?",
                      onConfirm: (): Promise<void> =>
                        cancelAppointment(item.clinicId, item.id),
                      noCancel: true,
                    });
                  } else {
                    showAlert({
                      type: EAlertVariant.Error,
                      header: "ERROR ALERT: OCDA2",
                      title: "Sorry, something went wrong",
                      message: "user has passed grace period",
                    });
                  }
                }}
                inGracePeriod={inGracePeriod}
              >
                Cancel
              </Styled.Link>{" "}
              |{" "}
              <Styled.Link
                onClick={(): void => {
                  if (inGracePeriod) {
                    const fullAppointment = item.fullAppointment;
                    bookingFlowContext.dispatch({
                      type: EBookingFlowAction.SetConsultationLocationId,
                      payload: fullAppointment.clinicId,
                    });
                    bookingFlowContext.dispatch({
                      type: EBookingFlowAction.SetAppointmentTypeId,
                      payload: fullAppointment.appointmentTypeId,
                    });
                    bookingFlowContext.dispatch({
                      type: EBookingFlowAction.SetPractitioner,
                      payload: {
                        practitionerId: fullAppointment.practitionerId,
                      },
                    });
                    bookingFlowContext.dispatch({
                      type: EBookingFlowAction.SetFullAppointmentForEdit,
                      payload: fullAppointment,
                    });
                    if (fullAppointment.clinicId) {
                      getAndSetAppointmentTypes(fullAppointment.clinicId);
                    }
                    // Calendar step in regular booking flow
                    bookingFlowContext.goToStep(5);
                    setAppointmentEditId(item.id);
                    setAppointmentClinicId(item.clinicId);
                  } else {
                    showAlert({
                      type: EAlertVariant.Error,
                      header: "ERROR ALERT: OCDA3",
                      title: "Sorry, something went wrong",
                      message: "user has passed grace period",
                    });
                  }
                }}
                inGracePeriod={inGracePeriod}
              >
                Edit
              </Styled.Link>
            </Styled.DetailEdit>
          )}
        </Styled.DetailHeader>
        <Styled.Detail>
          <Styled.DetailLabel>Doctor:</Styled.DetailLabel>{" "}
          {item.practitionerName}
        </Styled.Detail>
        <Styled.Detail>
          <Styled.DetailLabel>Clinic:</Styled.DetailLabel> {clinic?.[item.clinicId]?.displayName}
        </Styled.Detail>
        {!isPast && item.id && !inGracePeriod && (
          <Styled.NotInGracePeriodNotice>
            {ReactHtmlParser(
              data?.siteSettings?.nodes[0]?.myAccount_upcomingAppointmentsCopy
            )}
          </Styled.NotInGracePeriodNotice>
        )}
      </Styled.Details>
    </>
  );

  const showText =
    (type === AppointmentListType.BOTH &&
      upcomingAppointments.length === 0 &&
      pastAppointments.length === 0) ||
    (type === AppointmentListType.PAST && pastAppointments.length === 0) ||
    (type === AppointmentListType.UPCOMING &&
      upcomingAppointments.length === 0);

  return (
    <Styled.Container title={title} onInit={fetchAppointments}>
      {!appointmentEditId && (
        <>
          {showText &&
            (type === AppointmentListType.BOTH ||
              type === AppointmentListType.UPCOMING) && (
              <Styled.Text>
                You haven&apos;t booked any appointments yet or your
                appointments have not been confirmed. Appointments can take up
                to 10mins to appear here.
              </Styled.Text>
            )}
          {showText && type === AppointmentListType.PAST && (
            <Styled.Text>You have no past appointments.</Styled.Text>
          )}
          {[AppointmentListType.BOTH, AppointmentListType.UPCOMING].includes(
            type
          ) &&
            upcomingAppointments.length > 0 && (
              <Styled.Appointment isPast={false}>
                <Styled.Header>Upcoming</Styled.Header>
                {upcomingAppointments.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {renderItem({
                        item: item,
                        isPast: false,
                        // inGracePeriod: checkGracePeriod(item.date),
                      })}
                    </React.Fragment>
                  );
                })}
              </Styled.Appointment>
            )}
          {[AppointmentListType.BOTH, AppointmentListType.PAST].includes(
            type
          ) &&
            pastAppointments.length > 0 && (
              <Styled.Appointment isPast={true}>
                <Styled.Header>Past</Styled.Header>
                {pastAppointments.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {renderItem({ item: item, isPast: true })}
                    </React.Fragment>
                  );
                })}
              </Styled.Appointment>
            )}
        </>
      )}
      {appointmentEditId && (
        <Styled.Appointment isPast={false}>
          <OxEditAppointment
            appointmentId={appointmentEditId}
            clinicId={appointmentClinicId}
            setAppointmentEditId={setAppointmentEditId}
            fetchAppointments={fetchAppointments}
          />
        </Styled.Appointment>
      )}
    </Styled.Container>
  );
};
