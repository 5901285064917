import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { ESizes } from "src/config/enums";
import { fluidSizing } from "src/utils";
import { OxInput } from "src/components/OxInput";
import { OxLink } from "src/components/OxLink";

export const Back = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.primary.main};
    svg {
      transform: rotate(180deg);
      ${fluidSizing([
        { prop: "width", values: [15, 15, 15] },
        { prop: "height", values: [15, 15, 15] },
        { prop: "margin-right", values: [10, 10, 10] }
      ])}
    }
    ${fluidSizing([
      {
        prop: "margin-left",
        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
      },
      {
        prop: "margin-right",
        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
      },
      { prop: "margin-bottom", values: [15, 15, 15] }
    ])}
  `
);

export const Label = styled(OxLink)`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [10, 10, 10],
    [14, 14, 14]
  )}
`;

export const SearchContainer = styled.div<{
  searchResult: boolean;
  centerVertically: boolean;
}>(
  ({ theme, searchResult, centerVertically }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${centerVertically &&
      css`
        justify-content: center;
      `}

    > * {
      ${fluidSizing([{ prop: "width", values: [408, 408, 408] }])}
    }
    ${searchResult &&
      css`
        height: auto;
        justify-content: normal;
        background-color: ${theme.colors.basic.lightPanel};
        ${fluidSizing([
          { prop: "padding-bottom", values: [60, 60, 60] },
          { prop: "padding-top", values: [44, 44, 44] }
        ])}
      `}
  `
);

export const SearchWithResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fluidSizing([
    { prop: "margin-top", values: [15, 15, 15] },
    { prop: "margin-bottom", values: [15, 15, 15] }
  ])}
`;

export const Title = styled.div`
  text-align: center;
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Normal,
    [35, 35, 35],
    [37, 37, 37]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const SearchResult = styled.div`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Bold,
    [22, 22, 22],
    [26, 26, 26]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const SearchInput = styled(OxInput)`
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;
