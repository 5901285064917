import React from "react";
import * as Styled from "./OxSearchItem.styled";
import {EInputTheme, OxThemedInput} from "src/components/OxThemedInput";
import {OxButton} from "src/components/OxButton";

type TSearchItem = {
  fullName: string;
  id: string;
  onSelect: () => void;
}


export const OxSearchItem = (props: TSearchItem): JSX.Element => {
  return (
    <Styled.Container>
      <Styled.NameWithIdContainer>
        <Styled.FullName>{props.fullName}</Styled.FullName>
        <Styled.PatientId>{`ID:${props.id}`}</Styled.PatientId>
      </Styled.NameWithIdContainer>
      <OxThemedInput theme={EInputTheme.BackgroundGold}>
        <OxButton type="button" icon onClick={props.onSelect}>
          SELECT
        </OxButton>
      </OxThemedInput>
    </Styled.Container>
  );
};
