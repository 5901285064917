import React from "react";
import { RouteComponentProps } from "@reach/router";

import { OxPanelStaffDashboard } from "src/panel/components/OxPanelStaffDashboard";
import { OxPrivateRoute } from "src/panel/components/OxPrivateRoute";

export const PanelStaffDashboard = (
  props: RouteComponentProps
): JSX.Element => {
  return (
    <OxPrivateRoute
      component={OxPanelStaffDashboard}
      location={props.location}
    />
  );
};
