import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  OxCustomerDashboardAppointments,
  AppointmentListType,
} from "src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardAppointments";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxCustomerDashboardMyJourney: React.FC<
  RouteComponentProps
> = () => {
  return (
    <>
      <OxPageHelmet title="My Journey" />
      <OxCustomerDashboardAppointments
        title="My Journey"
        type={AppointmentListType.BOTH}
      />
    </>
  );
};
