import * as Styled from "./OxCustomerDashboardMyFiles.styled";

import React, { useContext, useEffect, useState } from "react";

import { RouteComponentProps } from "@reach/router";
import { getAttachments } from "src/services/api/patient";
import { TAttachment, TAttachments } from "src/services/api/api.types";
import { OxLink } from "src/components/OxLink";
import { EAlertVariant, AlertContext } from "src/context/AlertContext";
import { getUrl } from "src/services/api/api.core";
import { applyValues } from "src/utils/applyValues";
import { DateTime } from "luxon";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxCustomerDashboardMyFiles: React.FC<RouteComponentProps> = () => {
  const { validateResponse } = useValidateResponse();
  const [attachments, setAttachments] = useState<TAttachments>();
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    let unmounted = false;
    const asyncInit = async (): Promise<void> => {
      try {
        const attachments = await getAttachments();
        validateResponse(attachments);

        if (!unmounted && !!attachments) {
          setAttachments(attachments);
        }
      } catch (err) {
        showAlert({
          type: EAlertVariant.Error,
          header: "ERROR ALERT: OCDMF1",
          title: "Sorry, something went wrong",
          message: "There are no attachments avaliable",
        });
      }
    };
    asyncInit();
    return (): void => {
      unmounted = true;
    };
  }, []);

  const attachmentsExist = !!(
    attachments &&
    Object.values(attachments).find(
      (attachmentEntries: TAttachment[]) => attachmentEntries.length > 0
    )
  );

  return (
    <Styled.Container title="My Files">
      <OxPageHelmet title="My Files" />
      {!attachmentsExist && (
        <Styled.Text>
          No files have been uploaded to your account yet. Files can take up to
          10mins to appear here.
        </Styled.Text>
      )}
      {attachmentsExist &&
        !!attachments &&
        Object.entries(attachments).map(
          ([attachmentType, attachmentEntries]) => (
            <>
              <Styled.AttachmentSectionHeader>
                {attachmentType}
              </Styled.AttachmentSectionHeader>
              {attachmentEntries.length === 0 && (
                <Styled.Text>
                  No files of this type have been uploaded to your account yet.
                  Files can take up to 10mins to appear here.
                </Styled.Text>
              )}
              {attachmentEntries.map(
                (attachment: TAttachment, attachmentKey) => (
                  <Styled.Attachment key={attachmentType + attachmentKey}>
                    <Styled.FileNameWrapper>
                      <Styled.FileName>{attachment.filename}</Styled.FileName>
                      <Styled.FileDownload>
                        <OxLink
                          to={getUrl(applyValues(attachment.downloadUrl, {}))}
                        >
                          Download
                        </OxLink>
                      </Styled.FileDownload>
                    </Styled.FileNameWrapper>

                    {attachment.description && (
                      <Styled.FileDetail>
                        <Styled.FileDetailLabel>
                          Description:
                        </Styled.FileDetailLabel>
                        <Styled.FileDetailInfo>
                          {attachment.description}
                        </Styled.FileDetailInfo>
                      </Styled.FileDetail>
                    )}

                    <Styled.FileDetail>
                      <Styled.FileDetailLabel>Date:</Styled.FileDetailLabel>
                      <Styled.FileDetailInfo>
                        {DateTime.fromISO(attachment.updatedAt).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </Styled.FileDetailInfo>
                    </Styled.FileDetail>

                    <Styled.FileDetail>
                      <Styled.FileDetailLabel>Size:</Styled.FileDetailLabel>
                      <Styled.FileDetailInfo>
                        {attachment.size}kb
                      </Styled.FileDetailInfo>
                    </Styled.FileDetail>
                  </Styled.Attachment>
                )
              )}
            </>
          )
        )}
    </Styled.Container>
  );
};
