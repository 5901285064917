import * as Styled from "./OxStaffDashboardTreatmentNotes.styled";

import React, { useContext, useEffect, useState } from "react";

import { OxTreatmentNote } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardTreatmentNotes/components/OxTreatmentNote";
import { navigate, RouteComponentProps, WindowLocation } from "@reach/router";
import {
  TDamageReportItem,
  TTreatmentNote,
  TUser,
} from "src/services/api/api.types";
import {
  getTreatmentAppointmentSummary,
  getTreatmentNotes,
} from "src/services/api/treatment";
import { OxStaffDashboardRoot } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot";
import { OxStaffDashboardDamagedProductsTable } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardDamagedProductsTable";
import { postDamageReport } from "src/services/api/dear";
import { ERoles } from "src/config/enums";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { useStore } from "react-redux";
import { OxStaffDashboardPageSectionHeader } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxPageHelmet } from "src/components/OxPageHelmet";

type TState = {
  appointmentId: string;
  patientId?: string;
  clinicId: string;
  preSetTreatmentNotes?: TTreatmentNote[];
};

type TProps<RouteComponentProps> = RouteComponentProps & {
  location: WindowLocation & {
    state: TState;
  };
};

export const OxStaffDashboardTreatmentNotes: React.FC<
  RouteComponentProps<TProps<RouteComponentProps>>
> = ({ location }) => {
  const { validateResponse } = useValidateResponse();
  const store = useStore();
  const { user }: { user?: TUser } = store.getState();
  const { showAlert } = useContext(AlertContext);

  const [existingNotes, setExistingNotes] = useState<TTreatmentNote[]>([]);
  const [damagedProductsClearData, setDamagedProductsClearData] =
    useState<number>(0);

  const { appointmentId, clinicId, patientId, preSetTreatmentNotes } =
    location?.state ?? {
      preSetTreatmentNotes: [],
      appointmentId: "",
      clinicId: "",
      patientId: "",
    };

  const getDamagedProductSubmitPractitionerId = async (): Promise<
    string | false
  > => {
    if (user?.roles?.includes(ERoles.Practitioner)) {
      return false;
    } else {
      const appointment = await getTreatmentAppointmentSummary({
        clinicId,
        appointmentId: appointmentId ?? existingNotes[0].appointmentId,
      });
      return appointment?.practitionerId ?? false;
    }
  };

  const onDamagedProductsSubmit = async (
    data: TDamageReportItem[]
  ): Promise<void> => {
    postDamageReport(
      {
        practitionerId: await getDamagedProductSubmitPractitionerId(),
      },
      data
    )
      .then(validateResponse)
      .then(() => {
        showAlert({
          type: EAlertVariant.Confirm,
          header: "Damage report submitted successfully",
          onConfirm: () => {
            setDamagedProductsClearData((prev) => prev + 1);
          },
          onlyConfirm: true,
          hideAfterConfirm: true,
        });
      })
      .catch((e) => showAlert(e.error));
  };

  useEffect(() => {
    let unmounted = false;

    if ((preSetTreatmentNotes?.length ?? 0) > 0) {
      setExistingNotes(preSetTreatmentNotes ?? []);
    } else {
      getTreatmentNotes({ appointmentId, patientId })
        .then(validateResponse)
        .then((data) => !unmounted && setExistingNotes(data))
        .catch((e) => console.error(e.error.message));
    }

    return (): void => {
      unmounted = true;
    };
  }, []);

  return (
    <OxStaffDashboardRoot location={location}>
      <OxPageHelmet title="Treatment Notes" />
      <Styled.BackButton
        onClick={(): void => {
          navigate(-1);
        }}
      />
      {existingNotes.length > 0 ? (
        <>
          {existingNotes.map((existingNote, index) => (
            <OxTreatmentNote
              key={index}
              multipleExistingTreatmentNotes={existingNotes.length > 1}
              existingNote={existingNote}
              clinicId={clinicId ?? ""}
              appointmentId={existingNote?.appointmentId}
            />
          ))}
        </>
      ) : (
        <OxTreatmentNote appointmentId={appointmentId} clinicId={clinicId} />
      )}
      {/**
       * Only want to show damaged product section if there's 1 or no existing notes
       * As will be too confusing otherwise
       */}
      {existingNotes.length <= 1 && (
        <Styled.Container>
          <OxStaffDashboardPageSectionHeader>
            Damaged Products
          </OxStaffDashboardPageSectionHeader>
          <OxStaffDashboardDamagedProductsTable
            onSubmit={onDamagedProductsSubmit}
            triggerDataClear={damagedProductsClearData}
            clinicId={clinicId}
          />
        </Styled.Container>
      )}
    </OxStaffDashboardRoot>
  );
};
