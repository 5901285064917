import React from "react";

import { OxPractitionerDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxPractitionerDashboard";
import { OxReceptionistDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxReceptionistDashboard";
import { OxNurseDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxNurseDashboard";
import { OxMakeUpDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxMakeUpDashboard";
import { OxBaristaDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxBaristaDashboard";
import { OxSkincareConsultantDashboard } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRole/components/OxSkincareConsultantDashboard";
import { useStore } from "react-redux";
import { ERoles } from "src/config/enums";

export const OxStaffDashboardRoles = (): JSX.Element => {
  const store = useStore();
  const { user } = store.getState();

  const getRoleComponents = (): React.ReactFragment | undefined => {
    switch (true) {
      case user.roles.includes(ERoles.Practitioner):
        return <OxPractitionerDashboard />;
      case user.roles.includes(ERoles.Receptionist):
        return <OxReceptionistDashboard />;
      case user.roles.includes(ERoles.Nurse):
        return <OxNurseDashboard />;
      case user.roles.includes(ERoles.SkincareConsultant):
          return <OxSkincareConsultantDashboard />;
      case user.roles.includes(ERoles.MakeupArtist):
        return <OxMakeUpDashboard />;
      case user.roles.includes(ERoles.Barista):
        return <OxBaristaDashboard />;
      default:
        return <></>;
    }
  };

  return <React.Fragment>{getRoleComponents()}</React.Fragment>;
};
