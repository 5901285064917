import { EFontWeight, EFonts } from "src/config/enums";
import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { createTextStyle } from "src/utils/createTextStyle";
import { transparentize } from "polished";

export const Container = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up("m")} {
      width: ${toVw(245, "m")};
    }
  `
);

export const Tile = styled.div<{ vertical: boolean; type: string }>(
  ({ theme, vertical, type }) => css`
    width: 100%;
    height: auto;
    background-color: ${transparentize(0.5, theme.colors.basic.white)};
    display: flex;
    ${vertical &&
      css`
        flex-direction: column;
      `}
      ${type === "Initial Consultation" &&
        css`
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.basic.white};
          span:nth-child(2) {
            font-weight: ${EFontWeight.SemiBold};
          }
          span:nth-child(1),
          span:nth-child(3) {
            opacity: 0.7;
            font-weight: ${EFontWeight.Normal};
          }
        `}
        ${type === "Treatment" &&
          css`
            background-color: ${theme.colors.basic.disabledTile};
            span:nth-child(2) {
              font-weight: ${EFontWeight.SemiBold};
              color: ${theme.colors.basic.white};
            }
            span:nth-child(1),
            span:nth-child(3) {
              font-weight: ${EFontWeight.SemiBold};
            }
          `}
    ${fluidSizing([
      { prop: "margin-bottom", values: [3, 3, 3] },
      { prop: "padding-right", values: [20, 20, 20] },
      { prop: "padding-left", values: [20, 20, 20] },
      { prop: "padding-top", values: [10, 10, 10] },
      { prop: "padding-bottom", values: [10, 10, 10] }
    ])}
  `
);

export const Time = styled.span`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [13, 13, 13],
    [16, 16, 16]
  )}
`;

export const Availability = styled.span`
  opacity: 0.2;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [13, 13, 13],
    [16, 16, 16]
  )}
  ${fluidSizing([{ prop: "margin-left", values: [10, 10, 10] }])}
`;

export const Detail = styled.span`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [13, 13, 13],
    [16, 16, 16]
  )}
`;

export const CurrentDate = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.secondary.variant};
    text-align: center;
    text-transform: uppercase;
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.SemiBold,
      [13, 13, 13],
      [20, 20, 20]
    )}
    ${fluidSizing([
      { prop: "margin-bottom", values: [10, 10, 10] },
      { prop: "padding-top", values: [8, 8, 8] },
      { prop: "padding-bottom", values: [8, 8, 8] }
    ])}
  `
);
