import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { OxSelect } from "src/components/OxSelect";
import { OxButton } from "src/components/OxButton";

export const Detail = styled.div`
  ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}

  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [10, 10, 10],
    [12, 12, 12]
  )}

  @media print {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

export const DetailValue = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    font-weight: ${theme.fontWeights.semiBold};
  `
);

export const OutcomeText = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    font-weight: ${theme.fontWeights.semiBold};
    text-transform: uppercase;

    ${fluidSizing([
      { prop: "margin-bottom", values: [20, 20, 20] },
      { prop: "margin-top", values: [40, 40, 40] }
    ])}
  `
);

export const Select = styled(OxSelect)(
  () => css`
    ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
  `
);

export const Button = styled(OxButton)(
  () => css`
    ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
  `
);
