import * as Styled from "./OxCustomerDashboardEditPaymentCardForm.styled";

import React, { Dispatch, SetStateAction, useEffect } from "react";
import { STRIPE_CARD_OPTIONS } from "src/config/constants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import stripeJs from "@stripe/stripe-js";

type TProps = {
  setStripe: Dispatch<SetStateAction<stripeJs.Stripe | null>>;
  setElements: Dispatch<SetStateAction<stripeJs.StripeElements | null>>;
};

export const OxCustomerDashboardEditPaymentCardForm = ({
  setStripe,
  setElements
}: TProps) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setStripe(stripe);
  }, [stripe]);

  useEffect(() => {
    setElements(elements);
  }, [elements]);

  useEffect(() => {
    setStripe(stripe);
    setElements(elements);
  }, []);

  return (
    <Styled.CardWrapper>
      <CardElement options={STRIPE_CARD_OPTIONS} />
    </Styled.CardWrapper>
  );
};
