import styled from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";
import { OxForm } from "src/components/OxForm";
import { OxButton } from "src/components/OxButton";

export const Form = styled(OxForm)`
  ${fluidSizing([
    { prop: "margin-bottom", values: [10, 20, 40] },
    { prop: "margin-left", values: [10, 20, 40] },
    { prop: "margin-right", values: [10, 20, 40] }
  ])}

  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [10, 10, 10],
    [12, 12, 12]
  )}

  @media print {
    font-size: 1.6rem;
  }
`;

export const Section = styled.div``;

export const SectionHeaderStatus = styled.span`
  text-transform: capitalize;
`;

export const SubmitButton = styled(OxButton)`
  margin-left: auto;
  width: initial;

  ${fluidSizing([
    { prop: "margin-bottom", values: [10, 10, 20] },
    { prop: "margin-top", values: [10, 10, 20] }
  ])}

  @media print {
    display: none;
  }
`;
