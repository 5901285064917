import * as MessagesStyles from "../OxStaffDashboardAllMessages/OxStaffDashboardAllMessages.styled";

import React from "react";
import { RouteComponentProps } from "@reach/router";

import { OxDashboardMenu } from "src/panel/components/OxDashboardMenu";
import { ERoles, ERoutes } from "src/config/enums";
import { graphql, useStaticQuery } from "gatsby";
import { TMessage } from "src/types/message.types";
import { getReadMessageIds } from "src/services/messaging/messaging";
import { useStore } from "react-redux";

export const OxStaffDashboardMenu: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const store = useStore();
  const state = store.getState();

  const { roles } = state.user ?? {
    roles: [],
  };

  const {
    messages: { nodes: messages },
  } = useStaticQuery(graphql`
    query {
      messages: allSanityMessage(filter: { isGlobal: { eq: true } }) {
        nodes {
          id: _id
          title
          message
        }
      }
    }
  `);

  const unreadMessages = messages.filter(
    (message: TMessage) => !getReadMessageIds().includes(message.id)
  );

  const menuItems = [
    {
      title: "Landing",
      to: ERoutes.PanelStaffLanding,
    },
    {
      title: "Dashboard",
      to: ERoutes.PanelStaffDashboard,
      id: "dashboard",
    },
    {
      title: "Patients",
      to: ERoutes.PanelStaffDashboard,
      id: "patients",
      subItem: true,
    },
    {
      title: "Refreshments",
      to: ERoutes.PanelBaristaRefreshmentsList,
      hide: !roles.includes(ERoles.Barista),
    },
    {
      title: "Damaged Products",
      to: ERoutes.PanelStaffDashboardDamagedProducts,
      subItem: true,
      hide:
        !roles.includes(ERoles.Practitioner) && !roles.includes(ERoles.Nurse),
    },
    {
      title: "Calendar",
      to: ERoutes.PanelStaffDashboard,
      id: "appointments",
      subItem: true,
      hide:
        !roles.includes(ERoles.Practitioner) &&
        !roles.includes(ERoles.SkincareConsultant),
    },
    {
      title: "Finance",
      to: ERoutes.PanelStaffDashboard,
      id: "finance",
      subItem: true,
      hide: !roles.includes(ERoles.Practitioner),
    },
    {
      title: "Client Feedback",
      to: ERoutes.PanelStaffDashboard,
      id: "feedback",
      subItem: true,
      hide: !roles.includes(ERoles.Practitioner),
    },
    // {
    //   title: "Messages",
    //   to: ERoutes.PanelStaffDashboardMessages,
    //   children: unreadMessages.length > 0 && (
    //     <MessagesStyles.Count>{unreadMessages.length}</MessagesStyles.Count>
    //   ),
    //   subItem: true,
    //   hide: !!roles.includes(ERoles.SkincareConsultant),
    // },
    {
      title: "Patient Search",
      to: ERoutes.PanelStaffDashboardPatientSearch,
      subItem: true,
      hide:
        !roles.includes(ERoles.Practitioner) &&
        !roles.includes(ERoles.Nurse) &&
        !roles.includes(ERoles.Receptionist),
    },
    // {
    //   title: "No-show charge",
    //   to: ERoutes.PanelStaffDashboardNoShow,
    //   subItem: true,
    //   hide: !roles.includes(ERoles.Receptionist)
    // },
    {
      title: "Logout",
      logout: true,
      subItem: true,
    },
  ];

  return (
    <OxDashboardMenu
      menuItems={menuItems}
      variant="staff"
      location={location}
    />
  );
};
