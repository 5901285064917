import * as Styled from "./OxCustomerDashboardMyAccounts.styled";

import React, { useState } from "react";
import {
  getPatient,
} from "src/services/api/patient";

import { ERoutes } from "src/config/enums";
import { OxDashboardContentWrapper } from "../../../OxDashboardContentWrapper";
import { RouteComponentProps } from "@reach/router";
import { TPatient } from "src/services/api/api.types";
import { OxPageHelmet } from "src/components/OxPageHelmet";
import { applyValues } from "src/utils/applyValues";
import {getClinicById} from "src/services/api";

export const OxCustomerDashboardMyAccount: React.FC<
  RouteComponentProps
> = () => {
  const [patient, setPatient] = useState<[TPatient] | null>(null);

  const fetchData = async (): Promise<void> => {
    let data: [TPatient]|null = await getPatient();
    if (data){
      data = await Promise.all(data?.map(async (patientData): Promise<patientData> => {
        const clinic= await fetchClinicName({clinicId: patientData.clinicId});
        patientData.clinicName = clinic;
        return patientData;
      }));
      setPatient(data);
    }
  };

  const fetchClinicName = async ({clinicId}): Promise<string|undefined> => {
    const data = await getClinicById({clinicId});
    return data?.displayName;
  };

  // .filter removes unset data
  const labels = (patientData: TPatient) => {
    const detailArray = [
      { label: "Email:", labelData: patientData?.email },
      { label: "Date of birth:", labelData: patientData?.dateOfBirth },
      { label: "Occupation:", labelData: patientData?.occupation },
    ];

    patientData?.patientPhoneNumbers?.forEach((item) =>
      detailArray.push({ label: `${item.phone_type}:`, labelData: item.number })
    );

    return detailArray.filter((item) => item.labelData);
  }

  return (
    <Styled.Container>
      <OxPageHelmet title="My Account" />
          <OxDashboardContentWrapper
            title={`${patient?.[0]?.firstName || ""} ${patient?.[0].lastName || ""}`}
            noReturn
            noLoadingDelay
            onInit={fetchData}
          >
            {patient?.map((patientData, index) => (
            <Styled.ContactDetailsContainer key={index}>
              <Styled.HeaderContainer>
                <Styled.TitleText>MY DETAILS - {patientData.clinicName}</Styled.TitleText>
                <Styled.EditText to={applyValues(ERoutes.PanelCustomerDashboardDetails, {
                  patientId: patientData.patientId,
                })}>
                  EDIT
                </Styled.EditText>
              </Styled.HeaderContainer>
              <Styled.LabelsContainer>
                {labels(patientData).map((item, index) => (
                  <Styled.LabelWithDataContainer key={index}>
                <Styled.Label>{item.label}</Styled.Label>
                <Styled.LabelData>{item.labelData}</Styled.LabelData>
              </Styled.LabelWithDataContainer>
              ))}
            </Styled.LabelsContainer>
            </Styled.ContactDetailsContainer>
            ))}
          </OxDashboardContentWrapper>
    </Styled.Container>
  );
};
