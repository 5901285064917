import { EFontWeight, EFonts } from "src/config/enums";

import { OxButton } from "src/components/OxButton";
import { OxForm } from "src/components/OxForm";
import { OxInput } from "src/components/OxInput";
import { OxLink } from "src/components/OxLink";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import styled from "styled-components";

export const Container = styled(OxForm)`
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [27, 27, 27],
    [34, 34, 34]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const Paragraph = styled.p`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [13, 13, 13],
    [19, 19, 19]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Input = styled(OxInput)`
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Button = styled(OxButton)`
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const BackToLogin = styled(OxLink)`
  text-decoration: underline;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [13, 13, 13],
    [19, 19, 19]
  )}
`;
