import React from "react";

import { OxComponentContainer } from "src/components/OxComponentContainer";
import { OxStaffDashboardAppointmentCalendar } from "../../../OxStaffDashboardAppointmentCalendar";
import { OxStaffDashboardPatients } from "../../../OxStaffDashboardPatients";
import { EAppointmentsType } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments";
import { EType } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointmentCalendar/components/OxStaffTimeline";

export const OxSkincareConsultantDashboard = (): JSX.Element => {
  return (
    <>
      <OxComponentContainer
        marginTop={[40, 40, 40]}
        marginBottom={[40, 34, 50]}
      >
        <OxStaffDashboardPatients
          type={EAppointmentsType.LOCATION}
          id="patients"
        />
      </OxComponentContainer>
      <OxStaffDashboardAppointmentCalendar
        type={EType.USER}
        id="appointments"
      />
    </>
  );
};
