import * as Styled from "./OxPatientsFilters.styled";

import React from "react";

import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { TAppointment, TUser } from "src/services/api/api.types";
import { useStore } from "react-redux";
import { ERoles } from "src/config/enums";
import { DateTime } from "luxon";

type TProps = {
  appointments: TAppointment[];
  setFilters: React.Dispatch<React.SetStateAction<TFilter[]>>;
};

type LooseObject = {
  [key: string]: any;
};

export enum EFilterType {
  Eq = "EQ",
  NEq = "NEQ",
  DateRange = "DATE_RANGE"
}

export type TRangeFilter = { from?: DateTime; to?: DateTime };

export type TFilter = {
  key: keyof TAppointment;
  type: EFilterType;
  values: (string | number | TRangeFilter)[];
};

export const OxPatientsFilters = ({
  appointments,
  setFilters
}: TProps): JSX.Element => {
  const store = useStore();
  const { user }: { user?: TUser } = store.getState();
  // const visibleAppointments = appointments.filter(item => !item.hide);
  const timeFactory = (hour: number): DateTime =>
    DateTime.local()
      .setZone(user?.clinic?.timezone ?? "Europe/London")
      .set({ hour, minute: 0, second: 0, millisecond: 0 });

  const timeSelectItems: {
    label: string;
    range: TRangeFilter;
  }[] = [
    {
      label: "Before 10:00",
      range: {
        to: timeFactory(10)
      }
    },
    {
      label: "10:00 - 12:00",
      range: {
        from: timeFactory(10),
        to: timeFactory(12)
      }
    },
    {
      label: "12:00 - 14:00",
      range: {
        from: timeFactory(12),
        to: timeFactory(14)
      }
    },
    {
      label: "14:00 - 16:00",
      range: {
        from: timeFactory(14),
        to: timeFactory(16)
      }
    },
    {
      label: "After 16:00",
      range: {
        from: timeFactory(16)
      }
    }
  ];

  const filterUnique = (
    value: LooseObject,
    index: number,
    self: LooseObject[],
    key: string
  ): boolean =>
    self.findIndex((item: LooseObject) => item[key] === value[key]) === index;

  const updateFilters = (
    key: keyof TAppointment,
    type: EFilterType,
    selectedValues: LooseObject | undefined
  ): void => {
    setFilters(prev => {
      if (selectedValues) {
        const existingFilterIndex = prev.findIndex(
            filter => filter.key === key
          ),
          values = selectedValues.map((value: LooseObject) =>
            type != EFilterType.DateRange ? value[key] : value
          );

        if (existingFilterIndex >= 0) {
          prev[existingFilterIndex].values = values;
        } else {
          prev.push({
            key,
            type,
            values
          });
        }
      }

      return [...prev];
    });
  };

  const practitionerFilterItems = appointments
    .map(({ practitionerName, practitionerId }) => ({
      practitionerName,
      practitionerId
    }))
    .filter((value, index, self) =>
      filterUnique(value, index, self, "practitionerId")
    );

  return (
    <>
      <Styled.FilterList>
        <OxThemedInput theme={EInputTheme.GoldAlternative}>
          {!user?.roles?.includes(ERoles.Practitioner) && (
            <Styled.MultiSelect
              items={practitionerFilterItems}
              name="appointment_practitioner_filter"
              labelName="practitionerName"
              valueName="practitionerId"
              title="Filter by Doctor"
              onValueChange={(selectedValues: object | undefined): void =>
                updateFilters("practitionerId", EFilterType.Eq, selectedValues)
              }
              hasSelectAll={false}
              disableSearch={practitionerFilterItems.length < 5}
            />
          )}
          <Styled.MultiSelect
            items={timeSelectItems}
            name="appointment_time_filter"
            labelName="label"
            valueName="label"
            title="Appointment starts at"
            onValueChange={(selectedValues: any[] | undefined): void =>
              updateFilters(
                "startsAt",
                EFilterType.DateRange,
                selectedValues?.map(item => item.range)
              )
            }
            hasSelectAll={false}
            disableSearch={true}
          />
          {/*<Styled.MultiSelect
          items={appointments
            .map(({ appointmentType, appointmentTypeId }) => ({
              appointmentType,
              appointmentTypeId
            }))
            .filter((value, index, self) =>
              filterUnique(value, index, self, "appointmentTypeId")
            )}
          name="appointment_type_filter"
          labelName="appointmentType"
          valueName="appointmentTypeId"
          title="Filter by Appointment Type"
          onValueChange={(selectedValues: object | undefined): void =>
            updateFilters("appointmentTypeId", EFilterType.Eq, selectedValues)
          }
          hasSelectAll={false}
        />*/}
        </OxThemedInput>
      </Styled.FilterList>
    </>
  );
};
