import { EFontWeight, EFonts, ESizes } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxForm } from "src/components/OxForm";
import { OxImage } from "src/components/OxImage";
import { OxSpinner } from "src/components/OxSpinner";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled(OxImage)(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    ${theme.breakpoints.up("s")} {
      ${fluidSizing([
        {
          // set height to page height minus navigation
          prop: "min-height",
          values: [
            ESizes.NavbarHeight,
            ESizes.NavbarHeight,
            ESizes.NavbarHeight
          ],
          // The height of the nav is in vw so we deduct in vw
          formatter: (val: number): string => `calc(100vh - ${val}vw)`
        }
      ])}
    }
  `
);

export const Box = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.basic.white};
    ${fluidSizing([
      { prop: "max-width", values: [390, 390, 390] },
      { prop: "padding", values: [28, 40, 40] },
      { prop: "margin", values: [28, 40, 40] }
    ])}
  `
);

export const Label = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [12, 12, 12],
      [15, 15, 15]
    )};
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
  `
);

export const Title = styled.h2`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [27, 27, 27],
    [34, 34, 34]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;
export const Form = styled(OxForm)`
  position: relative;
  flex: 1;
  & > *:not(:last-child) {
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
  }
`;

export const Spinner = styled(OxSpinner)`
  ${fluidSizing([{ prop: "margin-bottom", values: [30, 30, 30] }])}
`;

export const SuccessText = styled.p(
  ({ theme }) => css`
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [14, 14, 14],
      [16, 16, 16]
    )};
    a {
      display: inline;
      color: ${theme.colors.primary.main};
    }
    ${fluidSizing([{ prop: "margin-bottom", values: [30, 30, 30] }])}
  `
);
