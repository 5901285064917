import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { OxStaffDashboardDamagedProductsDoctor } from "./components/OxStaffDashboardDamagedProductsDoctor";
import { OxStaffDashboardDamagedProductsTable } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardDamagedProductsTable";
import { ERoles, ERoutes } from "src/config/enums";
import { OxStaffDashboardReturn } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardReturn";
import { OxStaffDashboardPageSectionHeader } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader";
import { OxStaffDashboardRoot } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot";
import { TDamageReportItem, TPractitioner } from "src/services/api/api.types";
import { useStore } from "react-redux";
import { postDamageReport } from "src/services/api/dear";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { OxStaffDashboardContainer } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardContainer/OxStaffDashboardContainer";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxPageHelmet } from "src/components/OxPageHelmet";

type TProps<RouteComponentProps> = RouteComponentProps & unknown;

export const OxStaffDashboardDamagedProducts: React.FC<
  TProps<RouteComponentProps>
> = ({ location }) => {
  const { validateResponse } = useValidateResponse();
  const { showAlert } = useContext(AlertContext);
  const store = useStore();
  const { user } = store.getState();
  const clinicId = user.clinicId;

  const [doctor, setDoctor] = useState<TPractitioner | null>(null);

  const onSubmit = (data: TDamageReportItem[]): void => {
    if (!doctor) {
      showAlert({
        type: EAlertVariant.Error,
        header: "Please select a doctor",
      });

      return;
    }

    postDamageReport(
      {
        practitionerId: user?.roles.includes(ERoles.Practitioner)
          ? false
          : doctor?.practitionerId ?? false,
      },
      data
    )
      .then(validateResponse)
      .then(() => {
        showAlert({
          type: EAlertVariant.Confirm,
          header: "Damage report submitted successfully",
          onConfirm: () => {
            navigate(ERoutes.PanelStaffDashboard);
          },
          onlyConfirm: true,
          hideAfterConfirm: true,
        });
      })
      .catch((e) => showAlert(e.error));
  };

  useEffect(() => {
    user?.roles.includes(ERoles.Practitioner) &&
      setDoctor({
        title: user.title,
        firstName: user.firstname,
        lastName: user.lastname,
      } as TPractitioner);
  }, [user]);

  return (
    <OxStaffDashboardRoot location={location}>
      <OxPageHelmet title="Damaged Products" />
      <OxStaffDashboardContainer>
        <OxStaffDashboardReturn to={ERoutes.PanelStaffDashboard} />
        <OxStaffDashboardPageSectionHeader>
          Products Damaged
        </OxStaffDashboardPageSectionHeader>
        <OxStaffDashboardDamagedProductsDoctor
          doctor={doctor}
          setDoctor={setDoctor}
        />
        <OxStaffDashboardPageSectionHeader>
          List of Products
        </OxStaffDashboardPageSectionHeader>
        <OxStaffDashboardDamagedProductsTable
          onSubmit={onSubmit}
          clinicId={clinicId}
        />
      </OxStaffDashboardContainer>
    </OxStaffDashboardRoot>
  );
};
