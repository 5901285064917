import { OxPageHelmet } from "src/components/OxPageHelmet";
import { OxPanelRoot } from "src/panel/components/OxPanelRoot";
import { Provider } from "react-redux";
import React from "react";
import { store } from "src/panel/store";

const PanelPage = (): JSX.Element => {
  return (
    <Provider store={store}>
      <OxPageHelmet />
      <OxPanelRoot />
    </Provider>
  );
};

export default PanelPage;
