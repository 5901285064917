import styled, { css } from "styled-components";
import { OxButton } from "src/components/OxButton";
import { fluidSizing } from "src/utils";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { OxImage } from "src/components/OxImage";

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomSelect = styled(OxButton)`
  text-transform: capitalize;
  width: 100%;
  &:hover:enabled {
    svg {
      animation: none;
    }
  }
  svg {
    transform: rotate(90deg);
  }
`;

export const DropDownItem = styled.div<{ rightBorder: boolean }>(
  ({ theme, rightBorder }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    ${rightBorder &&
      css`
        border-right: 1px solid ${theme.colors.primary.main};
      `}
    ${fluidSizing([
      { prop: "height", values: [130, 130, 130] },
      { prop: "margin-top", values: [18, 18, 18] }
    ])}
  `
);

export const HorizontalLine = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.primary.main};
    ${fluidSizing([{ prop: "width", values: [24, 24, 24] }])}
  `
);

export const ItemLabel = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [8, 8, 8],
      [14, 14, 14]
    )}
    ${fluidSizing([{ prop: "margin-top", values: [5, 5, 5] }])}
  `
);

export const CustomImage = styled(OxImage)`
  ${fluidSizing([
    { prop: "width", values: [100, 100, 100] },
    { prop: "height", values: [100, 100, 100] },
    { prop: "margin-bottom", values: [15, 15, 15] }
  ])}
`;

export const DropDownListContainer = styled.div<{
  count: number;
  isOpen: boolean;
}>(
  ({ theme, count, isOpen }) => css`
    display: ${isOpen ? "grid" : "none"};
    grid-template-columns: repeat(${count}, 1fr);
    background-color: ${theme.colors.basic.white};
    border: 1px solid ${theme.colors.primary.main};
    border-top: none;
    ${fluidSizing([
      { prop: "width", values: [352, 352, 352] },
      { prop: "padding-bottom", values: [16, 16, 16] }
    ])}
  `
);
