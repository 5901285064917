import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { OxBackButton } from "src/components/OxBackButton";
import { OxImage } from "src/components/OxImage";
import { OxButton } from "src/components/OxButton";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 100%;
  }
`;

export const BackButton = styled(OxBackButton)`
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media print {
    display: none;
  }
`;

export const Header = styled.div<{ isPatient?: boolean }>(
  ({ theme, isPatient }) => css`
    position: relative;
    ${!isPatient &&
      css`
        color: ${theme.colors.primary.main};
        text-align: center;
      `}

    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.SemiBold,
      [18, 18, 18],
      [30, 30, 30]
    )}

    @media print {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.SemiBold,
        [16, 16, 16],
        [20, 20, 20]
      )}
    }
  `
);

export const DownloadButton = styled(OxButton)(
  () => css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media print {
      display: none;
    }
  `
);

export const SubHeader = styled.div`
  text-align: center;

  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [14, 14, 14],
    [30, 30, 30]
  )}

  @media print {
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.SemiBold,
      [12, 12, 12],
      [20, 20, 20]
    )}
  }
`;

export const Section = styled.div`
  ${fluidSizing([{ prop: "margin-bottom", values: [30, 30, 30] }])}
`;

export const SectionHeader = styled.div(
  ({ theme }) => css`
    border-bottom: 0.1rem solid ${theme.colors.basic.black};

    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.SemiBold,
      [16, 16, 16],
      [50, 50, 50]
    )}

    @media print {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.SemiBold,
        [12, 12, 12],
        [25, 25, 25]
      )}
    }
  `
);

export const Details = styled.div<{ isLast?: boolean; noBorder?: boolean }>(
  ({ theme, isLast }) => css`
    ${!isLast &&
      css`
        border-bottom: 0.1rem solid ${theme.colors.basic.ouronyxLogo};
      `}
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 100%;
    }

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Light,
      [10, 10, 10],
      [12, 12, 12]
    )}

    ${fluidSizing([
      { prop: "padding-top", values: [20, 20, 20] },
      { prop: "padding-bottom", values: [20, 20, 20] }
    ])}

    @media print {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Light,
        [8, 8, 8],
        [10, 10, 10]
      )}

      ${fluidSizing([
        { prop: "padding-top", values: [10, 10, 10] },
        { prop: "padding-bottom", values: [10, 10, 10] }
      ])}
    }
  `
);

export const DetailLine = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${fluidSizing([
    { prop: "padding-top", values: [5, 5, 5] },
    { prop: "padding-bottom", values: [5, 5, 5] }
  ])}

  @media print {
    ${fluidSizing([
      { prop: "padding-top", values: [2, 2, 2] },
      { prop: "padding-bottom", values: [2, 2, 2] }
    ])}
  }
`;
export const DetailLabel = styled.div<{ isPatient?: boolean }>(
  ({ isPatient }) => css`
    flex: 1 1 30%;
    ${!isPatient &&
      css`
        text-align: right;
      `}

    ${fluidSizing([
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}
  `
);

const valueWidth = "70%";

export const DetailValue = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    flex: 1 1 ${valueWidth};

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.SemiBold,
      [10, 10, 10],
      [12, 12, 12]
    )}

    ${fluidSizing([
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}

    @media print {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.SemiBold,
        [8, 8, 8],
        [10, 10, 10]
      )}
    }
  `
);

export const DetailDescription = styled.div<{ asValue?: boolean }>(
  ({ asValue }) => css`
    flex: 1 1 ${asValue ? valueWidth : "100%"};

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Light,
      [9, 9, 9],
      [12, 12, 12]
    )}

    ${fluidSizing([
      {
        prop: "padding-left",
        values: [15, 15, 15],
        formatter: (val): string =>
          asValue ? `${val}vw` : `calc(${val}vw + 30%)`
      },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}

  @media print {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Light,
        [8, 8, 8],
        [10, 10, 10]
      )}
    }
  `
);

export const SignatureContainer = styled.div`
  @media print {
    display: none;
  }
`;

export const SignatureCopy = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [10, 10, 10],
    [12, 12, 12]
  )}

  ${fluidSizing([
    { prop: "margin-bottom", values: [10, 10, 10] },
    { prop: "margin-top", values: [20, 20, 20] }
  ])}
`;

export const SignatureImage = styled(OxImage)`
  display: block;
  margin-left: auto;
  margin-right: auto;

  ${fluidSizing([
    { prop: "margin-bottom", values: [20, 20, 20] },
    { prop: "margin-top", values: [20, 20, 20] },
    { prop: "width", values: [100, 200, 300] }
  ])}
`;
