import styled from "styled-components";
import { OxDashboardContentWrapper } from "src/panel/components/OxDashboardContentWrapper";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { OxForm } from "src/components/OxForm";
import { fluidSizing } from "src/utils";

export const Container = styled(OxDashboardContentWrapper)`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [13, 13, 13],
    [19, 19, 19]
  )}
`;

export const SecondText = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [13, 13, 13],
    [19, 19, 19]
  )}

  ${fluidSizing([{ prop: "margin-bottom", values: [30, 30, 30] }])}
`;

export const FormWithMargin = styled(OxForm)`
  ${fluidSizing([{ prop: "margin-top", values: [30, 30, 30] }])}

  & > button {
    ${fluidSizing([{ prop: "margin-top", values: [30, 46, 48] }])}
  }
`;
