import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";

export const Table = styled.table(
  ({ theme }) => css`
    border-color: ${theme.colors.primary.main};
    border-spacing: 0;
    border-collapse: collapse;
    color: ${theme.colors.primary.main};
    text-align: left;
    width: 100%;

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [10, 10, 10],
      [18, 18, 18]
    )}

    @media print {
      font-size: 1.4rem;
    }
  `
);

export const Head = styled.tr(
  ({ theme }) => css`
    background: ${theme.colors.primary.main};
    color: ${theme.colors.basic.white};
  `
);

export const HeadCell = styled.td<{ last?: boolean }>(
  ({ theme, last }) => css`
    border-right: 1px solid
      ${!last ? theme.colors.basic.white : theme.colors.primary.main};

    ${fluidSizing([{ prop: "padding", values: [10, 10, 10] }])}

    @media print {
      border: 1px solid ${theme.colors.primary.main};
      padding: 0.5rem 2rem;
    }
  `
);

export const Row = styled.tr<{ hideOnPrint?: boolean }>(
  ({ hideOnPrint }) => css`
    ${hideOnPrint &&
      css`
        @media print {
          display: none;
        }
      `}
  `
);

export const Cell = styled.td(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.primary.main};
    ${fluidSizing([{ prop: "padding", values: [10, 10, 10] }])}

    @media print {
      padding: 0.5rem 2rem;
    }
  `
);
