import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";

export const CardWrapper = styled.div(
  ({ theme }) => css`
    align-items: center;
    border: 1px solid ${theme.colors.primary.main};
    display: flex;
    justify-content: center;

    ${fluidSizing([
      { prop: "height", values: [30, 30, 30] },
      { prop: "border-radius", values: [15, 15, 15] },
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}

    > * {
      flex: 1;
    }
  `
);
