import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { OxSelect } from "src/components/OxSelect";
import { OxIcon } from "src/components/OxIcon";

export const Container = styled.div`
  page-break-inside: avoid;

  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
`;

export const Text = styled.span``;

export const RemoveZone = styled.button(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.colors.primary.main};
    display: flex;
    font-weight: ${theme.fontWeights.semiBold};
    text-transform: uppercase;

    @media print {
      display: none;
    }
  `
);

export const RemoveIcon = styled(OxIcon)`
  transform: rotate(45deg);
  ${fluidSizing([{ prop: "margin-right", values: [5, 5, 5] }])}
`;

export const Select = styled(OxSelect)`
  ${fluidSizing([
    { prop: "margin-bottom", values: [10, 10, 10] },
    { prop: "margin-top", values: [10, 10, 10] }
  ])}

  @media print {
    display: none;
  }
`;
