import styled, { css } from "styled-components";

import { ESizes } from "src/config/enums";
import { fluidSizing } from "src/utils";

export const OxPanelLayout = styled.div(
  ({ theme }) => css`
    flex: 1;
    width: 100%;
    display: flex;
    ${theme.breakpoints.up("s")} {
      ${fluidSizing([
        {
          // set height to page height minus navigation
          prop: "min-height",
          values: [
            ESizes.NavbarHeight,
            ESizes.NavbarHeight,
            ESizes.NavbarHeight
          ],
          // The height of the nav is in vw so we deduct in vw
          formatter: (val: number): string => `calc(100vh - ${val}vw)`
        }
      ])}
    }
    & > * {
      width: 100%;
    }
  `
);
