import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { OxWelcomeInternalContentWrapper } from "src/panel/components/OxWelcomeIntenalFlow/components/OxWelcomeInternalContentWrapper";
import { OxRefreshments } from "src/panel/components/OxWelcomeIntenalFlow/components/OxRefreshments";
import { OxPageHelmet } from "src/components/OxPageHelmet";

export const OxStaffDashboardRefreshments: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const { patientId, patientName } = location.state;

  const onBack = () => {
    navigate(-1);
  };

  const onSuccess = (): void => {
    navigate(-1);
  };

  return (
    <OxWelcomeInternalContentWrapper header={patientName} onBack={onBack}>
      <OxPageHelmet title="Refreshments" />
      <OxRefreshments patientId={patientId} onNext={onSuccess} />
    </OxWelcomeInternalContentWrapper>
  );
};
