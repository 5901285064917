import { Store, applyMiddleware, combineReducers, createStore } from "redux";

import { TStore } from "./types";
import { authReducer } from "./auth/authReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { userReducer } from "./user/userReducer";

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
});

function configureStore(): Store<TStore> {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(reducers, composedEnhancers);

  return store;
}

export const store = configureStore();
