import * as Styled from "./OxTreatmentNotesZones.styled";

import React, { useContext, useEffect, useState } from "react";

import { OxTreatmentNotesZone } from "src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardTreatmentNotes/components/OxTreatmentNote/components/OxTreatmentNotesZones/components/OxTreatmentNotesZone";
import { getTreatmentZones } from "src/services/api/treatment";
import { TTreatmentZone } from "src/services/api/api.types";
import { randomString } from "src/utils/randomString";
import { TreatmentNoteContext } from "src/context/TreatmentNoteContext";
import { useValidateResponse } from "src/hooks/useValidateResponse";

type TProps = {
  clinicId: number;
};

export const OxTreatmentNotesZones = ({ clinicId }: TProps): JSX.Element => {
  const { validateResponse } = useValidateResponse();
  const { existingNote, clearProductData } = useContext(TreatmentNoteContext);
  const [existingZoneIds, setExistingZoneIds] = useState<number[]>([
    ...new Set(existingNote?.items.map(item => item.zone.id) ?? [])
  ]);
  const [selectedZoneIds, setSelectedZoneIds] = useState<number[]>(
    existingZoneIds
  );

  const generateZoneKey = (): string => `${Date.now()}${randomString(5)}`;

  const generateZoneKeyArray = (): string[] => {
    const requiredIdCount =
        existingZoneIds.length > 0 ? existingZoneIds.length : 1,
      returnArray = [];

    for (let i = 0; i < requiredIdCount; i++) {
      returnArray.push(generateZoneKey());
    }

    return returnArray;
  };

  const [zoneKeys, setZoneKeys] = useState<string[]>(generateZoneKeyArray);
  const [zones, setZones] = useState<TTreatmentZone[]>([]);

  const addZone = (): void => setZoneKeys(prev => [...prev, generateZoneKey()]);
  const removeZone = (
    keyToRemove: string,
    zoneId: number | undefined,
    isExisting?: boolean
  ): void => {
    isExisting &&
      setExistingZoneIds(prev => [...prev.filter(val => val !== zoneId)]);
    setZoneKeys(prev => {
      let newZoneKeys = [...prev.filter(id => id !== keyToRemove)];

      if (existingNote && zoneId) {
        existingNote.items = [
          ...existingNote.items.filter(item => item.zone.id !== zoneId)
        ];
      }

      if (newZoneKeys.length === 0) {
        clearProductData();
        newZoneKeys = [generateZoneKey()];
      } else if (zoneId) {
        clearProductData(zoneId);
      }

      setSelectedZoneIds(prev => [...prev.filter(id => id !== zoneId)]);

      return newZoneKeys;
    });
  };

  useEffect(() => {
    if (existingNote) {
      setZoneKeys(generateZoneKeyArray);
    }
  }, [existingNote]);

  useEffect(() => {
    let unmounted = false;

    getTreatmentZones()
      .then(data => validateResponse(data))
      .then(data => !unmounted && setZones(data))
      .catch(e => console.error(e.message));

    return (): void => {
      unmounted = true;
    };
  }, []);

  return (
    <Styled.Container>
      {zoneKeys.map((key, index) => (
        <OxTreatmentNotesZone
          key={key}
          generatedZoneKey={key}
          existingZoneId={existingZoneIds[index]}
          clinicId={clinicId}
          existingZoneItems={
            existingNote?.items.filter(
              item => item.zone.id === existingZoneIds[index]
            ) ?? []
          }
          addZone={addZone}
          removeZone={removeZone}
          zoneIndex={index}
          zoneCount={zoneKeys.length}
          zones={zones}
          selectedZoneIds={selectedZoneIds}
          setSelectedZoneIds={setSelectedZoneIds}
        />
      ))}
    </Styled.Container>
  );
};
