import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SignatureContainer = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.basic.lightPanel};
    border-color: ${theme.colors.primary.main};
    border-style: solid;
    border-width: 1px;

    & > * {
      width: 100%;
    }

    ${fluidSizing([
      { prop: "border-radius", values: [15, 15, 15] },
      { prop: "margin-bottom", values: [15, 15, 15] }
    ])}
  `
);

export const Actions = styled.div`
  ${fluidSizing([
    { prop: "margin-bottom", values: [15, 15, 15] },
    { prop: "margin-top", values: [15, 15, 15] }
  ])}
`;
