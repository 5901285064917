import styled, { css } from "styled-components";

export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 100%;
    position: relative;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
    }
  `
);

export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.43);
  flex: 1;
`;
