import * as Styled from "./OxTextSwitcher.styled";

import {
  EAnimatedWrapperAnimation,
  OxAnimatedWrapper
} from "src/components/OxAnimatedWrapper";

import React from "react";

type TProps = {
  items: string[];
  activeIndex: number;
  animationType: EAnimatedWrapperAnimation;
  textAs?: string;
};

export const OxTextSwitcher = ({
  textAs,
  items = [],
  activeIndex,
  animationType,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  //TODO: change when client get content
  // const longest = items.reduce((a, b) => (a.length > b.length ? a : b));
  const longest =
    items.length > 0
      ? items.reduce((a, b) => (a.length > b.length ? a : b))
      : "lorem ipsum";
  return (
    <Styled.Container {...props}>
      <Styled.Text as={textAs || "p"}>{longest}</Styled.Text>
      {items.map((item, index) => (
        <OxAnimatedWrapper
          key={index}
          animation={animationType}
          active={activeIndex === index}
          isNext={activeIndex < index}
        >
          <Styled.Text as={textAs || "p"}>{item}</Styled.Text>
        </OxAnimatedWrapper>
      ))}
    </Styled.Container>
  );
};
