import React from "react";
import {
  TAppointmentSummary,
  TTreatmentGoal,
  // TTreatmentInjectionPoint,
  TTreatmentNote,
  TTreatmentProduct
} from "src/services/api/api.types";

type None<T> = { [K in keyof T]?: never };

type TZoneAndVolume = {
  zoneId: number;
  injectionPointId: number;
  volume?: number;
};

export type TTreatmentNoteProductData =
  | (TZoneAndVolume & None<TTreatmentProduct>)
  | (TZoneAndVolume & TTreatmentProduct);

export type TTreatmentNoteContext = {
  multipleExistingTreatmentNotes?: boolean;
  existingNote: TTreatmentNote | undefined;
  appointmentSummary: TAppointmentSummary | undefined;
  // zones: {
  //   id: string;
  //   title: string;
  //   injectionPoint: TTreatmentInjectionPoint;
  //   product: TTreatmentProduct;
  // }[];
  setProductDatum: (data: TTreatmentNoteProductData) => void;
  setProductDatumVolume: (
    zoneId: number,
    injectionPointId: number,
    volume: number
  ) => void;
  productData: TTreatmentNoteProductData[];
  outcomeId?: number;
  setOutcomeId: (id: number) => void;
  clearProductData: (zoneId?: number) => void;
  treatmentGoals: TTreatmentGoal[];
  locked?: boolean;
};

export const TreatmentNoteContext = React.createContext(
  {} as TTreatmentNoteContext
);
