import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { OxLink } from "src/components/OxLink";
import { OxConsultation } from "src/components/OxConsultation";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidSizing([{ prop: "padding-bottom", values: [100, 100, 100] }])}
`;

export const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.basic.black};
    ${fluidSizing([{ prop: "padding-bottom", values: [14, 16, 16] }])}
  `
);

export const TitleText = styled.div(
  ({ theme }) => css`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [12, 12, 12],
    [20, 20, 20]
  )}
  color: ${theme.colors.primary.main};
  `
);

export const EditText = styled(OxLink)(
  ({ theme }) => css`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [10, 10, 10],
    [20, 20, 20]
  )}
  color: ${theme.colors.primary.main};
  `
);

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidSizing([{ prop: "margin-top", values: [26, 24, 24] }])}
`;

export const LabelWithDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${fluidSizing([{ prop: "margin-bottom", values: [12, 12, 12] }])}
`;

export const Label = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [13, 13, 13],
    [13, 13, 13]
  )}
  // Min width to vaguely line up label data
  ${fluidSizing([
    { prop: "margin-right", values: [48, 48, 48] },
    { prop: "min-width", values: [48, 48, 48] }
  ])}
`;

export const LabelData = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [13, 13, 13],
    [13, 13, 13]
  )}
`;

export const Consultation = styled(OxConsultation)(
  () => css`
    padding: 0;
  `
);
