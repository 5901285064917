import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxRatingStars } from "src/components/OxRatingStars";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { OxButton } from "src/components/OxButton";

export const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.basic.lightPanel};
    ${fluidSizing([
      { prop: "padding-top", values: [32, 32, 32] },
      { prop: "padding-bottom", values: [32, 32, 32] },
      { prop: "padding-right", values: [26, 38, 38] },
      { prop: "padding-left", values: [26, 38, 38] },
      { prop: "margin-left ", values: [0, 50, 60] },
      { prop: "margin-right ", values: [0, 50, 60] }
    ])}
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ${theme.breakpoints.only("xs")} {
      h3:first-of-type {
        width: 100%;
      }
      & > div {
        padding-left: 0;
      }
    }
    border-bottom: 0.1rem solid ${theme.colors.basic.black};
    ${fluidSizing([
      { prop: "margin-bottom", values: [22, 22, 22] },
      { prop: "padding-bottom", values: [20, 20, 20] }
    ])}
  `
);

export const HeaderText = styled.h3`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [22, 22, 22],
    [34, 34, 34]
  )}
`;

export const Error = styled.div`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [16, 16, 16],
    [34, 34, 34]
  )}
`;

export const OverallRating = styled(OxRatingStars)`
  ${fluidSizing([
    { prop: "padding-right", values: [10, 10, 10] },
    { prop: "padding-left", values: [10, 10, 10] }
  ])}
`;

export const Feedback = styled.div(
  ({ theme }) => css`
  &:not(:last-child) {
    ${fluidSizing([
      { prop: "margin-bottom", values: [28, 28, 28] },
      { prop: "padding-bottom", values: [28, 28, 28] }
    ])}
    border-bottom: 0.1rem solid ${theme.colors.basic.ouronyxLogo};
  }
`
);

export const RatingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${fluidSizing([{ prop: "margin-bottom", values: [28, 28, 28] }])}
`;

export const RatingBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${fluidSizing([{ prop: "margin-bottom", values: [25, 20, 20] }])}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RatingTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.SemiBold,
      [12, 12, 12],
      [20, 20, 20]
    )}
    ${fluidSizing([{ prop: "margin-bottom", values: [4, 4, 4] }])}
  `
);

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentText = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [12, 12, 12],
    [18, 18, 18]
  )}
`;

export const InitialsText = styled(ContentText)`
  ${fluidSizing([{ prop: "margin-bottom", values: [28, 28, 28] }])}
`;

export const ContentButton = styled(OxButton)`
  ${fluidSizing([
    { prop: "width", values: [178, 178, 178] },
    { prop: "margin-top", values: [31, 22, 32] }
  ])}
`;
