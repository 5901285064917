import styled, { css } from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";
import { OxLink } from "src/components/OxLink";

const width = 20;

export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    min-height: 100%;
    position: relative;
    width: 100%;
  `
);

export const Content = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.basic.alabaster};
    display: flex;
    flex: 1 1 100%;

    ${fluidSizing([
      { prop: "margin-left", values: [20, 20, 20] },
      { prop: "margin-right", values: [20, 20, 20] },
      { prop: "padding-top", values: [40, 40, 40] },
      { prop: "padding-right", values: [10, 10, 10] },
      { prop: "padding-bottom", values: [40, 40, 40] },
      { prop: "padding-left", values: [10, 10, 10] }
    ])}

    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
    }
  `
);

export const ItemContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.basic.wildSand};
    display: flex;
    flex: 1 1 50%;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    position: relative;
    width: 100%;

    ${fluidSizing([{ prop: "padding", values: [20, 20, 20] }])}

    ${theme.breakpoints.only("xs")} {
      flex-basis: 100%;
    }

    ${fluidSizing([{ prop: "margin-right", values: [width, width, width] }])}

    &::after {
      background-color: ${theme.colors.basic.swissCoffee};
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 0.1rem;

      ${fluidSizing([{ prop: "right", values: [-10, -10, -10] }])}
    }
  `
);

export const Number = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Bold,
      [10, 10, 10],
      [10, 10, 10]
    )}
  `
);

export const NumberInner = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    ${fluidSizing([
      { prop: "height", values: [20, 20, 20] },
      { prop: "flex-basis", values: [20, 20, 20] },
      { prop: "width", values: [20, 20, 20] }
    ])}
  `
);

export const Header = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    text-transform: uppercase;
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [14, 14, 14],
      [14, 14, 14]
    )}
    ${fluidSizing([{ prop: "margin", values: [10, 10, 10] }])}
  `
);

export const ItemContent = styled.div`
  flex: 1 1 100%;
  height: 100%;
  position: relative;
  width: 100%;

  ${fluidSizing([{ prop: "height", values: [300, 300, 300] }])}
`;

export const Return = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.primary.main};
    svg {
      transform: rotate(180deg);
      ${fluidSizing([
        { prop: "width", values: [15, 15, 15] },
        { prop: "margin-right", values: [9, 9, 9] }
      ])}
    }
    ${fluidSizing([
      { prop: "margin-left", values: [33, 30, 30] },
      { prop: "margin-top", values: [10, null, null] }
    ])}
  `
);

export const Label = styled(OxLink)`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [10, 10, 10],
    [14, 14, 14]
  )}
`;
