import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { OxInput } from "src/components/OxInput";

export const BatchInputContainer = styled.div<{ isFirst?: boolean }>(
  ({ isFirst }) => css`
    display: flex;
    ${!isFirst && fluidSizing([{ prop: "margin-top", values: [10, 10, 10] }])}
  `
);

export const BatchInput = styled(OxInput)`
  flex: 4;
`;
