import { TUser } from "src/services/api/api.types";

export type TStore = {
  auth: TAuthState;
};

export enum EAuthProcessStatus {
  Inaction = "AUTH_INACTION",
  Started = "AUTH_STARTED",
  PasswordProcessing = "AUTH_PASSWORD_PROCESSING",
  Verification = "AUTH_VERIFICATION",
  PasswordlessSent = "EMAIL_SENT",
  VerificationProcessing = "AUTH_VERIFICATION_PROCESSING",
  Success = "AUTH_SUCCESS",
  Error = "AUTH_ERROR",
}

export type TAuthError = string[];

export type TAuthState = {
  isLoggedIn: boolean;
  authProcessStatus: EAuthProcessStatus;
  authError: TAuthError;
};

export type TUserState = TUser | null;
