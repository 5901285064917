import styled, { css } from "styled-components";
import { OxMultiSelect } from "src/components/OxMultiSelect";
import { fluidSizing } from "src/utils";

export const FilterList = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}

    ${theme.breakpoints.only("xs")} {
      grid-template-columns: 1fr;
    }
  `
);

export const MultiSelect = styled(OxMultiSelect)`
  flex: 1 1 40%;
`;
