import { Redirect, RouteComponentProps } from "@reach/router";

import { ERoutes } from "src/config/enums";
import React from "react";
import { useStore } from "react-redux";

export const PanelHomePage = (
  props: RouteComponentProps<unknown>
): JSX.Element => {
  const store = useStore();
  const storeState = store.getState();
  if (storeState.auth.loggedIn) {
    return <Redirect to={ERoutes.PanelStaffDashboard} noThrow />;
  }
  return <Redirect to={ERoutes.PanelStaffLogin} noThrow />;
};
