import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { OxIcon } from "src/components/OxIcon";

export const Button = styled.button<{ disabled?: boolean; hideText?: boolean }>(
  ({ disabled, hideText }) => css`
    align-items: center;
    ${disabled &&
      css`
        cursor: default;
      `}
    display: flex;
    flex: 1 1 6rem;
    justify-content: flex-start;
    text-transform: uppercase;

    ${!hideText &&
      fluidSizing([{ prop: "padding-left", values: [15, 15, 15] }])}
    ${hideText &&
      css`
        margin-left: auto;
        margin-right: auto;
      `}

    @media print {
      display: none;
    }
  `
);

export const Icon = styled(OxIcon)<{ remove?: boolean; hideText?: boolean }>(
  ({ remove, hideText }) => css`
    ${remove &&
      css`
        transform: rotate(45deg);
      `}

    ${!hideText && fluidSizing([{ prop: "margin-right", values: [5, 5, 5] }])}
  `
);
