import * as Styled from "./OxLoginFlow.styled";

import {
  EAnimatedWrapperAnimation,
  OxAnimatedWrapper,
} from "src/components/OxAnimatedWrapper";
import React, { useState } from "react";

import { OxLoginFlowForgottenPassword } from "./components/OxLoginFlowForgottenPassword";
import { OxLoginFlowPasswordReset } from "./components/OxLoginFlowPasswordReset";
import { OxLoginFlowStage1 } from "./components/OxLoginFlowStage1";
import { OxLoginFlowStage2 } from "./components/OxLoginFlowStage2";
import { navigate } from "gatsby";
import { OxLoginPasswordlessStage1 } from "src/panel/components/OxLoginFlow/components/OxLoginPasswordlessStage1";

export enum EOxLoginFlowType {
  Staff = "STAFF",
  Customer = "CUSTOMER",
}

export enum EOxLoginFlowVariant {
  LoginFlow = "LOGIN_FLOW",
  ForgottenPassword = "FORGOTTEN_PASSWORD",
  ResetPassword = "RESET_PASSWORD",
}

export type TOxLoginFlowCodeLogin = {
  username: string;
};

export type TOxLoginFlowStageProps = {
  stage: {
    id: string;
    title?: string;
    component: any;
    returnUrl?: string;
    returnState?: any;
    email?: string;
  };
  variant: EOxLoginFlowVariant;
  currentStage: number;
  setCurrentFlow: React.Dispatch<React.SetStateAction<EOxLoginFlowVariant>>;
  goToNextStage: () => void;
};

type TProps = {
  variant: EOxLoginFlowType;
  state?: {
    returnUrl?: string;
    forceReLogin?: boolean;
    returnState?: any;
    email?: string;
  };
};

export const OxLoginFlow = ({ variant, state }: TProps): JSX.Element => {
  const [currentStage, setCurrentStage] = useState(0);
  const [currentFlow, setCurrentFlow] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) return EOxLoginFlowVariant.ResetPassword;
    return EOxLoginFlowVariant.LoginFlow;
  });

  const staffFlow = [
    {
      id: "login",
      title: "Staff Login",
      returnUrl: state?.returnUrl,
      returnState: state?.returnState,
      email: state?.email,
      component: OxLoginFlowStage1,
    },
    {
      id: "verification",
      returnUrl: state?.returnUrl,
      returnState: state?.returnState,
      component: OxLoginFlowStage2,
    },
  ];

  const clientFlow = [
    {
      id: "login",
      title: "Client Login",
      returnUrl: state?.returnUrl,
      returnState: state?.returnState,
      email: state?.email,
      component: OxLoginPasswordlessStage1,
    },
  ];

  const stages = {
    [EOxLoginFlowVariant.LoginFlow]:
      variant === EOxLoginFlowType.Staff ? staffFlow : clientFlow,
    [EOxLoginFlowVariant.ForgottenPassword]: [
      {
        id: "forgotten_password",
        component: OxLoginFlowForgottenPassword,
      },
    ],
    [EOxLoginFlowVariant.ResetPassword]: [
      {
        id: "password_reset",
        component: OxLoginFlowPasswordReset,
      },
    ],
  };

  const goToNextStage = (): void => {
    setCurrentStage((prev) => prev + 1);
  };

  return (
    <Styled.Container>
      <Styled.Label>Access Your Account</Styled.Label>
      <Styled.Content className="login-flow">
        {stages[currentFlow].map((stage, index) => (
          <OxAnimatedWrapper
            key={stage.id}
            animation={EAnimatedWrapperAnimation.Stack}
            active={index === currentStage}
            relativeBox
          >
            {React.createElement(stage.component, {
              stage,
              goToNextStage,
              currentStage,
              setCurrentFlow,
              variant: variant,
            })}
          </OxAnimatedWrapper>
        ))}
        {!!state?.returnUrl && (
          <Styled.BackButton
            onClick={(): void => {
              navigate(state?.returnUrl ?? "", {
                state: { ...state?.returnState, loginAutoSubmit: false },
              });
            }}
          />
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
