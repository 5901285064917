import { EFontWeight, EFonts } from "src/config/enums";
import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { OxCalendar } from "src/components/OxCalendar";
import { createTextStyle } from "src/utils/createTextStyle";
import { transparentize } from "polished";

export const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.secondary.main};
    ${fluidSizing([
      { prop: "padding", values: [30, 40, 40] },
      { prop: "margin-left ", values: [0, 50, 60] },
      { prop: "margin-right ", values: [0, 50, 60] }
    ])}
  `
);

export const Heading = styled.h3`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [22, 22, 22],
    [34, 34, 34]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const Filters = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.primary.main};
    color: ${theme.colors.primary.main};
    button {
      text-transform: uppercase;
    }
    ${fluidSizing([{ prop: "padding-bottom", values: [20, 20, 20] }])};
  `
);

export const FilterItem = styled.button<{ active: boolean }>(
  ({ active }) => css`
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [12, 12, 12],
      [20, 20, 20]
    )}
    ${active &&
      css`
        font-weight: ${EFontWeight.SemiBold};
        pointer-events: none;
      `}
    ${fluidSizing([{ prop: "padding-right", values: [15, 15, 15] }])};
  `
);

export const FilterViewAll = styled.button`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [10, 10, 10],
    [20, 20, 20]
  )}
`;

export const Content = styled.div(
  ({ theme }) => css`
    display: grid;
    ${theme.breakpoints.down("s")} {
      grid-template-columns: 100%;
    }
    ${theme.breakpoints.up("m")} {
      grid-template-columns: auto auto;
      grid-column-gap: ${toVw(20, "m")};
      align-items: start;
    }
    ${fluidSizing([{ prop: "margin-top", values: [30, 30, 30] }])};
  `
);

export const Calendar = styled(OxCalendar)(
  ({ theme }) => css`
    background-color: ${transparentize(0.5, theme.colors.basic.white)};
    ${theme.breakpoints.up("m")} {
      width: ${toVw(255, "m")};
      height: 100%;
      display: flex;
      align-items: flex-start;
    }
    .react-calendar {
      background-color: transparent;
      ${fluidSizing([
        { prop: "max-height", values: [260, 260, 260] },
        { prop: "padding-left", values: [22, 30, 30] },
        { prop: "padding-right", values: [22, 30, 30] },
        { prop: "padding-top", values: [15, 15, 15] }
      ])};
      &__navigation {
        ${fluidSizing([
          { prop: "height", values: [22, 22, 22] },
          { prop: "margin-bottom", values: [15, 15, 15] }
        ])};
        span {
          ${createTextStyle(
            EFonts.Montserrat,
            EFontWeight.Normal,
            [14, 14, 16],
            [18, 18, 22]
          )}
        }
      }
      &__month-view__weekdays {
        ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])};
      }
      &__tile {
        ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])};
        &:after {
          ${fluidSizing([
            { prop: "width", values: [26, 26, 26] },
            { prop: "height", values: [26, 26, 26] }
          ])};
        }
      }
    }
  `
);
