import { TPatientProfile } from "./api.types";
import { apiGetCall } from "src/services/api/api.core";

import { EApiEndpoint } from "src/config/enums";
import { applyValues } from "src/utils/applyValues";

export const getPatientProfile = async (values: {
  patientId: string;
}): Promise<TPatientProfile | null> => {
  return await apiGetCall(applyValues(EApiEndpoint.PatientProfile, values), {
    credentials: true
  });
};
